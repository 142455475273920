// Modals

.modal-backdrop {
	z-index: $backdrop-modal-z;
	opacity: 0;
}

.modal {
	background: rgba(0, 0, 0, 0.3);
	z-index: $modal-z;
	height: 100% !important;

	&.fade {
		.modal-dialog {
			&.modal-dialog-slideout {
				-webkit-transform: translate(100%, 0) scale(1);
				transform: translate(100%, 0) scale(1);
			}
		}
		&.show {
			.modal-dialog {
				&.modal-dialog-slideout {
					-webkit-transform: translate(0, 0);
					transform: translate(0, 0);
					display: flex;
					align-items: stretch;
					-webkit-box-align: stretch;
					height: 100%;
					.modal-body {
						overflow-y: auto;
						overflow-x: hidden;
					}
				}
			}
		}
	}
}

.md .modal-dialog {
	max-width: 565px;
}

// General styles for modals
.pk-modal {
	font-family: $mainFont;
	span.value {
		img {
			max-width: 100% !important;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	.modal-content {
		border: 1px solid $pkBlue;
		border-radius: 0.5rem;
		.modal-header {
			background-color: $pkBlue;
			color: $white;
			border-radius: 0.4rem 0.4rem 0 0;
			padding: 1rem 1.5rem;
			&.brokertest,
			&.brokerdevelopment {
				background: #550000;
			}
			&.brokerstaging {
				background: #004400;
			}
			.modal-title {
				font-size: 1rem;
				font-weight: 700;
				color: $white;
			}
			.close {
				float: none;
				text-shadow: none;
				opacity: 1;
				color: $white;
				&:hover,
				&:focus {
					color: $gray;
				}
			}
		}
		.modal-body {
			font-size: 0.8rem;
			padding: 1.5rem;
			color: $darkGray;
			.big-blue-text {
				color: $pkBlue;
				font-size: 1.2rem;
				font-weight: 600;
			}
			.deposit-required {
				font-weight: 700;
				color: $red !important;
			}
			.value {
				a {
					i,
					fa-icon {
						font-size: 1rem;
						vertical-align: middle;
						margin-right: 0rem;
					}
				}
			}
			.top-row-actions {
				.btn {
					color: #1e91d6;
				}
			}
		}
		.modal-top-actions-bar {
			.modal-top-actions {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-end;
			}
		}
		.modal-footer {
			border-top: none;
			border-bottom-right-radius: 0.4rem;
			border-bottom-left-radius: 0.4rem;
			padding: 1.5rem 1.5rem;
		}
		.status-bar {
			counter-reset: step;
			padding: 2rem 2rem 2rem 0;
			&.in-modal {
				z-index: $modal-status-bar-z;
				ul {
					li {
						font-size: 0.65rem;
						line-height: 0.9rem;
					}
				}
			}
			ul {
				margin-bottom: 6rem;
				li {
					list-style-type: none;
					float: left;
					position: relative;
					text-align: center;
					font-weight: 600;
					font-size: 0.5rem;
					&:before {
						font-size: 20px;
						font-weight: 700;
						width: 40px;
						height: 40px;
						content: counter(step);
						counter-increment: step;
						line-height: 40px;
						display: block;
						text-align: center;
						margin: 0 auto 10px auto;
						border-radius: 50%;
						background-color: $gray;
					}
					&:after {
						width: 100%;
						height: 2px;
						content: "";
						position: absolute;
						background-color: $gray;
						top: 20px;
						left: -50%;
						z-index: -1;
					}
					&:first-child:after {
						content: none;
					}
					&.active {
						color: $lightBlue;
						font-weight: 700;
						&:before {
							background-color: $lightBlue;
							color: $white;
						}
						& + li:after {
							background-color: $lightBlue;
						}
					}
					&.passed {
						font-weight: 600;
						&:before {
							background-color: $blueGray;
							color: $white;
						}
						& + li:after {
							background-color: $blueGray;
						}
					}
				}
			}
		}

		.large-bullets {
			padding-inline-start: 1rem;
			list-style: none;
			& li::before {
				content: "\2022";
				color: $pkBlue !important;
				display: inline-block;
				width: 1rem;
				margin-left: -1rem;
				font-weight: bold;
				line-height: 1.25rem;
				font-size: 1.25rem;
			}
		}
	}
	.modal-full,
	&.modal-full {
		max-width: 90vw;
	}
	.modal-lg,
	&.modal-lg {
		max-width: 800px;
	}

	.label-heading {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0;
		&.label-style-light {
			font-weight: 500;
			color: $darkGray;
		}
		&.label-style-bold {
			font-weight: 600;
			color: $black;
		}
		p {
			font-size: 0.75em;
			margin-bottom: 0.5rem;
			display: inline-block;
			&.count-validation {
				color: $green;
				font-weight: 600;
			}
		}
	}
	.label-style {
		font-size: 0.85em;
		margin: 0 0 0.5em 0;
		&.label-style-light {
			font-weight: 500;
			color: $darkGray;
		}
		&.label-style-bold {
			font-weight: 600;
			color: $black;
		}
	}

	.leadlink-product-modal,
	&.leadlink-product-modal {
		.modal-content {
			.modal-header {
				background-color: $white;
				border-radius: 1.5rem 1.5rem 0 0;
				padding: 1rem 1.5rem 0;
				border-bottom: none;
				.close {
					float: none;
					text-shadow: none;
					opacity: 1;
					color: $lightBlue;
					&:hover,
					&:focus {
						color: $blue;
						outline: none;
					}
				}
			}
			.modal-body {
				padding: 0 1.5rem 1.5rem;
				.product-image {
					width: 100%;
					height: auto;
					max-width: 150px;
				}
				h5 {
					color: $darkBlue;
					font-size: 2.25rem;
					font-weight: 600;
					margin-bottom: 0;
				}
				.product-price {
					color: $darkBlue;
					font-size: 2.5rem;
					font-weight: 700;
					margin-bottom: 0;
				}
				.success-message {
					background-color: $offWhite;
					border: 2px solid $green;
					border-radius: 0.5rem;
					padding: 1rem;
				}
				.error-message {
					color: $red;
					background-color: $faintPink;
					border: 2px solid $red;
					border-radius: 0.5rem;
					padding: 1rem;
				}
			}
		}
	}

	// Details Modal
	.details-modal,
	&.details-modal {
		.modal-content {
			.modal-body {
				font-size: 0.8rem;
				.supplier-logo {
					max-height: 4rem;
				}
				.detail-title {
					text-align: left;
					padding: 1rem 0 0.5rem;
				}
				.detail-content {
					padding: 0;
				}
				.autosize-xs {
					width: 100%;
					text-align: center;
				}
				.btn-plan {
					color: $white !important;
					line-height: 3 !important;
				}
				.commodity-icon {
					padding-right: 0.25rem;
					img {
						height: 2.5rem;
						width: auto;
					}
				}
				.commodity-label {
					padding-left: 0.25rem;
					.commodity-uof {
						font-size: 0.75rem;
						margin-bottom: 0;
						&.electricity-color {
							color: $orange;
						}
						&.gas-color {
							color: $pkBlue;
						}
					}
					.commodity-number {
						font-size: 1rem;
						font-weight: 600;
						margin-bottom: 0;
					}
				}
				.closing-ratio-label {
					font-weight: 500;
					font-size: 1rem;
				}
				.closing-ratio-number {
					font-weight: 700;
					font-size: 2rem;
					sup {
						font-size: 1.25rem;
					}
				}
				.divider-left {
					border-left: none;
					border-top: 1px solid $darkGray;
				}
				.black-header {
					color: $black;
					font-weight: 600;
				}
			}
			.modal-footer {
				.btn {
					font-size: 0.8rem;
				}
			}
		}
	}

	// How it's Determined Modal
	.how-its-determined-modal,
	&.how-its-determined-modal {
		.modal-content {
			.modal-body {
				font-size: 0.8rem;
				.upfront-table {
					padding: 0 2rem;
					.upfront-table-header {
						border-top: 2px solid $darkGray;
						background-color: $offRowGray;
						font-weight: 600;
					}
					.upfront-right-column {
						border-left: 2px solid $darkGray;
					}
				}
				.residual-table {
					overflow-x: scroll;
					.table-striped {
						thead {
							tr {
								th {
									white-space: nowrap;
									border: 1px solid $outlineGray;
									padding: 0.5rem 0.75rem;
									&.double-border {
										border-right: 4px solid $outlineGray;
									}
								}
							}
						}
						tbody {
							tr {
								td {
									white-space: nowrap;
									border: 1px solid $outlineGray;
									padding: 0.5rem 0.75rem;
									&.double-border {
										border-right: 4px solid $outlineGray;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// Savings Modal
	.savings-modal,
	&.savings-modal {
		.modal-content {
			.modal-body {
				.est-savings-highlight {
					color: $pkBlue;
					font-size: 1.5rem;
					font-weight: 700;
				}
				.small-text-note {
					font-size: 0.75rem;
					margin-top: 1rem;
				}
			}
		}
	}

	.edi-modal,
	&.edi-modal {
		&.modal-xl {
			font-size: 0.9rem;
		}
		&.modal-lg {
			font-size: 0.8rem;
		}
		.modal-content {
			.modal-body {
				.container-fluid {
					.top-row-left {
						padding: 1.5rem 1rem 2rem 2rem;
						.dropdown {
							.dropdown-toggle {
								font-size: 0.85rem;
							}
							.dropdown-menu {
								.header-left {
									font-weight: 600;
									border-bottom: 2px solid $pkBlue;
									margin-bottom: 0.5rem;
									padding-left: 1.85rem;
									padding-bottom: 0.25rem;
								}
								.header-right {
									font-weight: 600;
									border-bottom: 2px solid $pkBlue;
									margin-bottom: 0.5rem;
									padding-bottom: 0.25rem;
								}
								.scrolling-area {
									min-width: 30rem;
									.loc-usage-error-entry {
										&:link,
										&:visited,
										&:active {
											color: $darkerOutlineGray !important;
											text-decoration: none;
										}
										&:hover,
										&:focus {
											color: $lightBlue !important;
											text-decoration: none;
										}
									}
								}
							}
						}
					}
					.top-row-right {
						padding: 1.5rem 2rem 2rem 1rem;
						text-align: right;
					}
					.chart-div {
						padding: 0 2rem 2rem;
					}
					.error-msg {
						color: $red;
						font-weight: 700;
						margin-bottom: 1.5rem;
						a {
							&:link,
							&:visited,
							&:active,
							&:focus {
								color: $pkBlue !important;
								text-decoration: none;
							}
							&:hover {
								color: $lightBlue !important;
								text-decoration: none;
							}
						}
					}
					.loc-usage-errors-collapse {
						background-color: $offRowGray;
						border-top: 1px solid $darkGray;
						margin-top: 0.5rem;
						margin-bottom: 0.5rem;
					}
					.usage-info {
						padding: 1.75rem 1rem 1rem 2rem;
						background-color: $offRowGray;
						border-radius: 0 0 0 1.5rem;
					}
					.usage-table {
						padding: 0.75rem 2rem 1rem 1rem;
						table {
							thead {
								tr {
									th {
										text-align: center;
										border-top: none;
									}
								}
							}
							tbody {
								tr {
									td {
										text-align: center;
									}
								}
							}
						}
					}

					.loc-usage-header-row {
						display: none;
					}

					.loc-usage-error-row {
						margin-bottom: 0.25rem;
						padding-top: 1rem;
						border-top: 1px solid $darkGray;
						p {
							margin-bottom: 0.5rem;
						}
					}
				}
			}
		}
	}

	.rate-calendar-modal,
	&.rate-calendar-modal {
		.fa {
			cursor: pointer;
			font-size: 0.9rem;
		}
		.axis-title {
			font-size: 1rem;
			font-weight: 700;
		}
		.forward-chevron,
		.back-chevron {
			a {
				display: block;
				&:link,
				&:visited,
				&:active {
					color: $darkGray !important;
					text-decoration: none;
				}
				&:hover,
				&:focus {
					color: $lightGray !important;
					text-decoration: none;
				}
				&.disabled {
					&:link,
					&:visited,
					&:active {
						color: $gray !important;
						text-decoration: none;
						cursor: default;
					}
					&:hover,
					&:focus {
						color: $gray !important;
						text-decoration: none;
						cursor: default;
					}
				}
			}
		}
		.y-axis-label {
			width: 20px;
			height: 100%;
			.up-chevron {
				transform: rotate(-90deg);
				position: absolute;
				top: 38px;
				left: -35px;
				width: 90px;
				text-align: end;
			}
			.axis-title {
				transform: rotate(-90deg);
				white-space: nowrap;
				position: absolute;
				top: 43%;
				left: -48px;
			}
			.down-chevron {
				transform: rotate(-90deg);
				position: absolute;
				bottom: 53px;
				left: -34px;
				width: 90px;
			}
			.up-chevron,
			.down-chevron {
				a {
					display: block;
					&:link,
					&:visited,
					&:active {
						color: $darkGray !important;
						text-decoration: none;
					}
					&:hover,
					&:focus {
						color: $lightGray !important;
						text-decoration: none;
					}
					&.disabled {
						&:link,
						&:visited,
						&:active {
							color: $gray !important;
							text-decoration: none;
							cursor: default;
						}
						&:hover,
						&:focus {
							color: $gray !important;
							text-decoration: none;
							cursor: default;
						}
					}
				}
			}
		}
		.rate-calendar-table {
			font-size: 0.75rem;
			th {
				background-color: $lightGray !important;
				font-weight: 600;
				text-align: center;
			}
			td {
				font-weight: 500;
				text-align: center;
				&.red-cell {
					color: $red !important;
					background-color: $pink !important;
					font-weight: 700;
				}
				&.green-cell {
					color: $green !important;
					background-color: $lightGreen !important;
					font-weight: 700;
				}
				&.empty-cell {
					background-color: $lightGray !important;
				}
			}
			.highlight-row {
				th,
				td {
					background-color: $tooltipBlue !important;
					font-weight: 700;
				}
			}
		}
	}

	&.configure-table-modal {
		.column-choices-header {
			color: $black;
			font-weight: 600;
			font-size: 0.75em;
			border-bottom: 2px solid $pkBlue;
			& > div {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}
		.column-choice {
			color: $black;
			font-weight: 500;
			font-size: 0.85em;
			& > div {
				padding-top: 0.75rem;
				padding-bottom: 0.25rem;
				&.col-8 {
					padding-bottom: 0.7rem;
				}
			}
		}
		.dx-state-focused {
			background-color: rgba(0, 0, 0, 0.04) !important;
			.dx-list-reorder-handle {
				color: #333 !important;
			}
		}

		.dx-list {
			.dx-item:nth-of-type(1),
			.dx-item:nth-of-type(2),
			.dx-item:nth-of-type(3) {
				.dx-list-reorder-handle {
					display: none;
				}
			}
		}
		.dx-list-item-content {
			padding: 5px 10px;
		}
	}
	&.modal-dialog-slideout {
		min-height: 100%;
		margin: 0 0 0 auto;
		background: $white;
		border-left: 1px solid $darkBlue;
		.modal-content {
			border: 0;
			.modal-header {
				background-color: $darkBlue;
				padding: 1rem 1.5rem;
				border-radius: 0;
				color: $white;
			}
			.modal-body {
				padding: 0;
				font-size: 0.85rem;
				.modal-top {
					background-color: $darkBlue;
					.close {
						text-shadow: none;
						opacity: 1;
						color: $white;
						&:hover,
						&:focus {
							color: $gray;
							outline: unset;
						}
					}
					.go-back {
						text-shadow: none;
						opacity: 1;
						color: $white;
						font-weight: 600;
						position: relative;
						bottom: 8px;
						&:hover,
						&:focus {
							color: $gray;
							outline: unset;
							text-decoration: none;
						}
						i,
						fa-icon {
							font-size: 1.25rem;
							vertical-align: bottom;
							margin-right: 0.5rem;
							position: relative;
							top: 5px;
						}
					}
					label {
						color: $white;
					}
					.form-symbol {
						color: $darkGray;
						top: 2.3rem;
					}
				}
				.grey-row {
					background-color: $lightGray;
				}
				.faq-answer {
					p {
						margin: 0;
					}
				}
				.search-panel {
					background-color: $white;
				}
				.feedback-panel {
					background-color: $lightGray;
					padding-bottom: 1rem;
					height: 100vh;
					.modal-top {
						background-color: $lightGray;
						.close,
						.go-back {
							color: $blue;
							&:hover,
							&:focus {
								color: $lightBlue;
							}
						}
					}
					.feedback-content {
						margin-top: 10vh;
						.form-control {
							background-color: $offRowGray;
							border: 2px solid $outlineGray;
							border-radius: 0.25rem;
							font-size: 0.85rem;
							font-weight: 500;
						}
					}
				}
				h4 {
					color: $darkGray;
				}
				.search-commodity-icon {
					height: 3rem;
					width: auto;
				}
				.larger-side-icon {
					i,
					fa-icon {
						font-size: 3rem;
						color: $blueGray;
					}
					a {
						i,
						fa-icon {
							font-size: 3rem;
							color: $blueGray;
							margin-right: 0;
							vertical-align: bottom;
						}
					}
				}
				ul,
				ol {
					li {
						margin-bottom: 0.5rem;
					}
				}
				.alt-lead-account {
					background-color: $offRowGray;
					margin-top: 2rem;
					padding-top: 1rem;
					padding-bottom: 1rem;
					.energy-icon {
						height: 2.5rem;
						width: auto;
					}
					.collapse-link {
						font-weight: 600;
						&::before {
							content: "Less Info";
						}
						&.collapsed {
							&::before {
								content: "More Info";
							}
						}
					}
				}
			}
		}
		.modal-footer {
			background-color: $lightGray;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-top: none;
			padding: 1rem 0;
			font-size: 0.85rem;
			.initial-link {
				font-weight: 600;
				i,
				fa-icon {
					font-size: 1.25rem;
					vertical-align: bottom;
					margin-right: 0.5rem;
				}
			}
			.left-link {
				padding: 0.25rem 0.75rem 0.25rem 0;
				font-weight: 600;
			}
			.right-link {
				padding: 0.25rem 0 0.25rem 0.75rem;
				border-left: 1px solid $pkBlue;
				font-weight: 600;
			}
		}
	}

	&.missing-incorrect-ptc-modal {
		.opening-link {
			padding: 0;
			a {
				display: block;
				padding: 3rem 0;
				font-weight: 600;
				&:link,
				&:visited,
				&:active {
					transition: 0.25s ease-in-out;
					i,
					fa-icon {
						font-size: 2.5rem;
						margin: 0 0 0.5rem 0;
					}
				}
				&:hover,
				&:focus {
					background-color: $halfBlue;
				}
			}
			&.right-side {
				border-left: 1px solid $pkBlue;
			}
		}
	}
}

//
.home-page-takeover-modal {
	.modal-dialog {
		max-width: 488;
	}
	.modal-header {
		padding: 1px;
		border-bottom: 0;
		.close {
			padding: 0;
			margin: 0;
			color: $white;
			text-shadow: none;
			opacity: 1;
			float: unset;
			position: absolute;
			top: -12px;
			right: -12px;
			z-index: 2062;
			i,
			fa-icon {
				font-size: 2.5rem;
			}
			&:link,
			&:visited,
			&:active {
				i,
				fa-icon {
					color: $white;
				}
			}
			&:focus {
				outline: none !important;
			}
			&:hover {
				color: $halfBlue;
				opacity: 1;
			}
		}
	}
	.modal-body {
		font-size: 0.85rem;
		padding-bottom: 0;
		h5 {
			font-size: 1.25rem;
			color: $pkBlue;
		}
	}
	.modal-footer {
		padding-top: 0;
		padding-bottom: 1.5rem;
		border-top: 0;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
	.pk-modal {
		// Details Modal
		.details-modal,
		&.details-modal {
			.modal-content {
				.modal-body {
					.autosize-xs {
						width: auto;
						text-align: unset;
					}
				}
			}
		}
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
	.pk-modal {
		// Details Modal
		.details-modal,
		&.details-modal {
			.modal-content {
				.status-bar {
					padding: 2rem 0;
					ul {
						margin-bottom: 1rem;
						li {
							font-size: 1rem;
							&:before {
								font-size: 28px;
								width: 50px;
								height: 50px;
								line-height: 50px;
							}
							&:after {
								top: 25px;
							}
						}
					}
				}
			}
		}
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	.pk-modal {
		// Details Modal
		.details-modal,
		&.details-modal {
			.modal-content {
				.modal-body {
					.detail-title {
						text-align: right;
						padding: 1rem 1rem 0 0;
					}
					.detail-content {
						padding: 1rem 0 0 1rem;
					}
					.divider-left {
						border-left: 1px solid $darkGray;
						border-top: none;
					}
				}
			}
		}

		.edi-modal,
		&.edi-modal {
			.modal-content {
				.modal-body {
					.container-fluid {
						.loc-usage-header-row {
							display: flex;
							font-weight: 600;
							border-bottom: 1px solid $darkGray;
							margin-bottom: 0.5rem;
							p {
								margin-top: 0.5rem;
								margin-bottom: 0.5rem;
							}
						}

						.loc-usage-error-row {
							margin-bottom: 0.75rem;
							padding-top: 0;
							border-top: none;
						}
					}
				}
			}
		}
	}
}

// direct node_module css imports
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../../node_modules/devextreme/dist/css/dx.common.css";
@import "../../node_modules/devextreme/dist/css/dx.light.css";
@import "video.js/dist/video-js.css";

// google fonts
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@1,700&display=swap");

// additional library css/scss
@import "jsoneditor/dist/jsoneditor.min.css";

// bootstrap and overrides
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "./bootstrap-config";
@import "bootstrap/scss/bootstrap";

@import "ngx-toastr/toastr-bs4-alert";

// config and library overrides
@import "./config";
@import "./main";
@import "./grid";
@import "./dx-overrides";
@import "./tinymce-overrides";
@import "./popover";
@import "./map";
@import "./tooltip";
@import "./dropdown-overrides";
@import "./stripe-overrides";
@import "./toastr-overrides";

// put all normal page imports here
@import "./support-center";
@import "./navbar";
@import "./contract-info";
@import "./home-page";
@import "./market-update";
@import "./market-analytics.scss";
@import "./progress-bar-overrides";
@import "./configure-table-modal";
@import "./supplier-dashboard";
@import "./supplier-api";
@import "./instant-quote";
@import "./feedback";
@import "./reports";
@import "./commission-process";
@import "./setup";
@import "./submit-customer";
@import "./crm";

// specific overrides
@import "./forms";
@import "./tables";
@import "./modals";
@import "./news";
@import "./upsell-ads";

// shared styles
@import "./notification";
@import "./monthly-grid";
@import "./pk-framework";

// special load dashboard styles
@import "./rate-matrix-loader-dashboard/custom";

// special market analytics dashboard styles
@import "./market-analytics";

// 	Styles for elements applying to the framework of the PK site

body {
	font-family: $mainFont;
	background-color: $white;

	.home-page-container,
	.contract-info-container,
	.instant-quote-container,
	.support-center-container,
	.covid-container,
	.market-analytics-container,
	.supplier-container,
	.profile-container,
	.reports-container,
	.supplierAPI-container,
	.setup-container,
	.news-container {
		min-height: calc(100% - 61px);
		padding-top: 10em;
		&.container-fluid,
		&.container-xl {
			padding-top: 10em !important;
			padding-bottom: 3em !important;
			&.customer-view {
				padding-top: 7em !important;
			}
		}
		&.reduce-padding-top {
			padding-top: 0 !important;
		}
	}

	.market-analytics-container {
		min-height: calc(100% - 61px);
		padding-left: 2rem !important;
		padding-right: 2rem !important;
		padding-top: 9.5em !important;
		padding-bottom: 3em !important;

		&.container-fluid,
		&.container-xl {
			padding-top: 9.5em !important;
		}
	}

	&.login {
		background-color: #a9a9a9;
	}

	.btn {
		&.btn-pk-blue {
			background-color: $iconLinkBlue !important;
			color: $white !important;
		}
	}

	.clearfix {
		clear: both;
	}
}

.classic-tabs {
	&.contract-page-tab-navigation,
	&.profile-page-tab-navigation {
		.nav-tabs {
			margin-bottom: 1.5rem;
			box-shadow:
				0 2px 5px 0 rgba(0, 0, 0, 0.16),
				0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
		}
	}
	&.classic-tabs-justify-content-center {
		.nav-tabs {
			-webkit-box-pack: center !important;
			-ms-flex-pack: center !important;
			justify-content: center !important;
		}
	}
	&.classic-tabs-justify-content-start {
		.nav-tabs {
			-webkit-box-pack: start !important;
			-ms-flex-pack: start !important;
			justify-content: start !important;
		}
	}
	&.classic-tabs-justify-content-end {
		.nav-tabs {
			-webkit-box-pack: end !important;
			-ms-flex-pack: end !important;
			justify-content: end !important;
		}
	}
	.nav-tabs {
		width: 100%;
		background: $white;
		font-size: 0.87em;
		.nav-item {
			margin-bottom: 0;
			&:hover,
			&:focus {
				background-color: $halfBlue !important;
			}
			.nav-link {
				border: none;
				padding: 1em 2.25em 1.25em 2.25em;
				&:link,
				&:visited {
					color: $darkBlue !important;
				}
				&.active {
					background: none;
					font-weight: 700;
					border-bottom: 3px solid $darkBlue;
				}
			}
		}
	}
}

// Header with navigation and breadcrumbs
header {
	font-family: $roboto;
	position: fixed;
	z-index: $header-z;
	.top-row {
		background: $pkBlue;
		color: white;
		border-bottom: 1px solid $gray;
		min-height: 50px;
		&.brokertest,
		&.brokerdevelopment {
			background: #550000;
		}
		&.brokerstaging {
			background: #004400;
		}
		.main-logo {
			padding: 0.5em 0 0 1.2em;
			a,
			span {
				img {
					height: 45px;
					width: auto;
				}
			}
		}
		.top-nav {
			ul {
				padding: 0.7em 0 0 0;
				li {
					display: inline;
					padding: 0 0 0 0.5em;
					font-size: 0.9em;
					a {
						&:link,
						&:visited,
						&:active,
						&:focus {
							color: $white !important;
						}
						&:hover {
							color: $gray !important;
							text-decoration: none;
						}
						&.collapse-link {
							opacity: 0;
							transition: opacity 0.5s;
						}
						&.collapsed {
							opacity: 1;
						}
					}
					i,
					fa-icon {
						margin-right: 0.25em;
					}
				}
			}
		}
		// Frequent Broker Colors
		.diamond-style {
			color: $diamond;
		}
		.platinum-style {
			color: $white;
		}
		.gold-style {
			color: $yellow;
		}
		.silver-style {
			color: $silver;
		}
		.full-color-style {
			color: $lightBlue;
		}
		.financial-profile {
			font-family: $mainFont;
			h2 {
				font-size: 16px;
				font-weight: 600;
				color: $white;
				margin-bottom: 1rem;
			}
			a.collapse-link {
				&:link,
				&:visited,
				&:active {
					color: $halfBlue;
					text-decoration: underline;
				}
				&:hover,
				&:focus {
					color: $white;
					text-decoration: none;
				}
			}
			.blue-area-link {
				font-size: 0.75rem;
				&:link,
				&:visited,
				&:active {
					color: $halfBlue;
					text-decoration: underline;
				}
				&:hover,
				&:focus {
					color: $white;
					text-decoration: none;
				}
				i,
				fa-icon {
					font-size: 1.25rem;
					margin-right: 0.5rem;
					vertical-align: bottom;
				}
			}
			table {
				color: $white;
				font-size: 0.85rem;
				thead {
					th {
						border-bottom: 2px solid $tooltipBlue;
					}
				}
				th,
				td {
					border-top: unset;
					&.add-top-border {
						border-top: 1px solid $tooltipBlue;
					}
				}
				th {
					vertical-align: bottom;
					line-height: 1.25rem;
					&.add-bottom-border {
						border-bottom: 2px solid $tooltipBlue;
					}
				}
				td {
					&.add-bottom-border {
						border-bottom: 1px solid $tooltipBlue;
					}
				}
			}
			.blue-area-divider {
				border-top: 1px solid $outlineGray;
				margin: 0 0 1rem 0;
				color: unset;
			}
			.add-left-border {
				border-left: none;
			}
			.financial-profile-levels {
				color: $gray;
				.fb-level {
					text-align: center;
					.fb-emblem {
						font-size: 2.25rem;
						margin-bottom: 0;
					}
					.fb-level-name {
						font-size: 0.5rem;
						font-weight: 700;
						text-transform: uppercase;
						margin-bottom: -3px;
						margin-top: -5px;
					}
					.fb-level-percentage {
						font-size: 1.1rem;
						font-weight: 600;
					}
					&.not-reached {
						opacity: 0.5;
					}
				}
			}
			.fb-level-path {
				text-align: center;
				.fb-path-number {
					font-size: 0.5rem;
					padding-top: 11px;
					margin-bottom: 0;
				}
				.fb-path-arrow {
					opacity: 0.5;
					margin-bottom: 0;
				}
			}
			.blue-area-label {
				color: $tooltipBlue;
				font-weight: 600;
				font-size: 0.65rem;
				margin-bottom: 0;
				.tooltip-icon {
					color: $iconLinkBlue;
				}
			}
			.blue-area-data-item {
				color: $white;
				font-weight: 700;
				text-transform: uppercase;
				font-size: 1.65rem;
				i,
				fa-icon {
					margin-right: 0.5rem;
					vertical-align: middle;
				}
				.smaller-text {
					font-size: 1rem;
					font-weight: 600;
					vertical-align: middle;
				}
				&.remove-line-height {
					line-height: 1.75rem;
				}
			}
			.alert-icon {
				i,
				fa-icon {
					color: $red;
					font-size: 1.5rem;
				}
			}
			.custom-control-input {
				& ~ .blue-area-switch {
					&::before {
						border-color: $tooltipBlue !important;
						background-color: $pkBlue;
					}
					&::after {
						background-color: $tooltipBlue;
					}
				}
				& + .blue-area-switch {
					span::after {
						content: "Keep Open?";
						font-size: 0.65rem;
						position: absolute;
						right: 42px;
						top: 5px;
						white-space: nowrap;
						color: $tooltipBlue;
					}
				}
				&:checked,
				&:active {
					& ~ .blue-area-switch {
						&::before {
							border-color: $lightBlue !important;
							background-color: $lightBlue;
						}
						&::after {
							background-color: $white;
						}
					}
				}
				&:checked {
					& + .blue-area-switch {
						span::after {
							content: "Keep Open";
							font-size: 0.65rem;
							position: absolute;
							right: 42px;
							top: 5px;
							white-space: nowrap;
							color: $white;
						}
					}
				}
			}
		}
	}
	.bottom-row {
		z-index: $header-bottom-row-z;
		background: $white;
		color: $darkGray;
		border-bottom: 1px solid $gray;
		box-shadow:
			0 2px 5px 0 rgba(0, 0, 0, 0.16),
			0 2px 10px 0 rgba(0, 0, 0, 0.12);
		overflow-y: hidden;
		.side-nav-toggle {
			padding: 0;
			box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.16);
			i {
				padding: 1em 1.2em;
			}
		}
		.breadcrumb-nav {
			.breadcrumb {
				background: none;
				padding: 0.75em;
				margin-bottom: 0;
				a {
					&:link,
					&:visited {
						color: $lightBlue;
					}
					&:hover,
					&:focus {
						color: $blue;
						text-decoration: none;
					}
				}
			}
		}
		.search-nav {
			ul {
				padding: 0.8em 0 0 0;
				li {
					display: inline;
					padding: 0 0 0 2em;
					font-size: 1em;
					i {
						margin-right: 0.25em;
					}
					&.main-nav-link {
						a {
							position: relative;
							width: 55px;
							display: inline-block;
							.collapse-menu-text,
							.collapse-close-text {
								white-space: nowrap;
								position: absolute;
								right: -3px;
								top: -17px;
								i,
								fa-icon {
									margin-left: 0.5rem;
								}
							}
						}
					}
					.badge-alert-bubble {
						color: $white;
						background-color: $blueGray;
						vertical-align: text-bottom;
						font-weight: 500;
						&.new-alerts {
							background-color: $red;
							animation-name: delayed-bounce;
							animation-timing-function: ease;
							animation-duration: 4.5s;
							animation-iteration-count: infinite;
							transform-origin: bottom;
						}
					}
					&.hide-nav {
						display: none !important;
					}
				}
				#searchForm {
					i,
					fa-icon {
						display: inline-block;
					}
					input {
						height: calc(1em + 0.75rem + 2px);
						border: none;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
						box-shadow: none;
						background-color: $white;
						width: 4rem;
						padding-right: 0;
						-webkit-transition: all 0.4s ease-in-out;
						transition: all 0.4s ease-in-out;
						margin-top: -0.3rem;
						display: inline-block;
					}
					input:focus {
						border-bottom: 2px solid #ced4da;
						padding-right: 0.75rem;
						width: 12.5rem;
					}
				}
			}
		}
		.main-nav {
			font-weight: 500;
			font-size: 0.85rem;
			padding: 0 1.75rem;
			a.mainNavLink {
				&:link,
				&:visited,
				&:active {
					color: $pkBlue !important;
				}
				&:hover,
				&:focus {
					color: $lightBlue !important;
				}
				i,
				fa-icon {
					margin-right: 0.5rem;
					font-size: 1.25rem;
					vertical-align: sub;
				}
			}
		}
	}
}
// Side Panel Navigation
.main-content {
	// Side panel navigation
	.side-panel-nav {
		background: $blue;
		color: $lightGray;
		padding-right: 0;
		padding-left: 0;
		ul {
			list-style-type: none;
			padding: 0;
			li {
				padding: 0.8em;
				i {
					font-size: 1.15em;
				}
			}
		}
	}
}

// Footer
footer {
	background: $black;
	.footer-logo {
		padding: 0.5em 0 0.5em 1.2em;
	}
	.footer-copy {
		font-family: $roboto;
		color: $halfWhite;
		font-size: 0.7em;
		padding: 2em 1.5em 2em 0;
	}
}

// Video Player CSS
.video-js {
	border: 1px solid $outlineGray;
	.vjs-big-play-button {
		top: calc(50% - 0.81666em);
		left: calc(50% - 1.5em);
	}
}

// Take a Tour button
.tour-button {
	margin-top: -41px;
	.btn-sm {
		border-radius: 0 0 0.2rem 0.2rem;
	}
	.btn-secondary {
		background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 100%);
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 100%);
		&:focus,
		&:not(:disabled):not(.disabled):active:focus {
			box-shadow: none;
		}
	}
}

// Collapse with Chevron and Details Text
.custom-collapse {
	.collapse-header {
		a {
			&.collapse-toggle {
				&:link,
				&:visited,
				&:active,
				&:focus {
					color: $pkBlue;
					font-weight: 600;
					display: block;
					.collapse-symbol {
						.details-text {
							opacity: 0;
							transition: opacity 1s;
							display: inline-block;
							font-size: 0.75rem;
							margin-right: 0.5rem;
						}
						i,
						fa-icon {
							transition: 0.3s transform ease-in-out;
							vertical-align: baseline;
							font-size: 1rem;
							svg {
								transition: 0.3s transform ease-in-out;
							}
						}
					}
				}
				&:hover {
					color: $lightBlue;
				}
				&.light-blue {
					&:link,
					&:visited,
					&:active,
					&:focus {
						color: $lightBlue;
						font-weight: 600;
					}
					&:hover {
						color: $blue;
					}
				}
				&.collapsed {
					.collapse-symbol {
						.details-text {
							opacity: 1;
						}
						i,
						fa-icon {
							transform: rotate(180deg);
							svg {
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
	header {
		.top-row {
			// Frequent Broker Progress Bar
			.fb-level-path {
				.fb-path-arrow {
					margin-top: 14px;
					img {
						width: 45px;
						height: 4px;
					}
				}
			}
			.top-nav {
				ul {
					li {
						display: inline;
						padding: 0 0 0 2em;
					}
				}
			}
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	header {
		.top-row {
			// Frequent Broker Progress Bar
			.fb-level-path {
				.fb-path-arrow {
					margin-top: -7px;
					img {
						width: 80px;
						height: 4px;
					}
				}
			}
			.top-nav {
				ul {
					li {
						display: inline;
						padding: 0 0 0 2em;
					}
				}
			}
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	header {
		.top-row {
			// Frequent Broker Progress Bar
			.fb-level-path {
				.fb-path-arrow {
					img {
						width: 100px;
						height: 4px;
					}
				}
			}
			.top-nav {
				ul {
					li {
						display: inline;
						padding: 0 0 0 2em;
					}
				}
			}
		}
		.bottom-row {
			.main-nav {
				padding: 0 6rem 0 10rem;
			}
		}
	}
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	header {
		.top-row {
			.financial-profile {
				.add-left-border {
					border-left: 2px solid $white;
				}
				// Frequent Broker Progress Bar
				.financial-profile-levels {
					.fb-level {
						.fb-emblem {
							font-size: 1.75rem;
						}
						.fb-level-name {
							font-size: 0.35rem;
						}
						.fb-level-percentage {
							font-size: 1.1rem;
						}
					}
				}
				.fb-level-path {
					.fb-path-number {
						font-size: 0.5rem;
					}
					.fb-path-arrow {
						img {
							width: 80px;
							height: 4px;
						}
					}
				}
			}
			.top-nav {
				ul {
					li {
						display: inline;
						padding: 0 0 0 2em;
					}
				}
			}
		}
		.bottom-row {
			.main-nav {
				padding: 0 12rem 0 20rem;
			}
		}
	}
}

// 	Styles for beyond 1200px.
@media (min-width: 1366px) {
	header {
		.top-row {
			// Frequent Broker Progress Bar
			.financial-profile-levels {
				.fb-level {
					.fb-emblem {
						font-size: 2.25rem;
					}
					.fb-level-name {
						font-size: 0.5rem;
					}
				}
			}
			.fb-level-path {
				.fb-path-arrow {
					img {
						width: 100px;
						height: 4px;
					}
				}
			}
			.top-nav {
				ul {
					li {
						display: inline;
						padding: 0 0 0 2em;
					}
				}
			}
		}
	}
}

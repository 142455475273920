// 	Styles specific for form fields within the Rate Grid.

.pk-modal,
.instant-quote-container,
.home-page-container,
.contract-info-container,
.supplier-container,
.support-center-container,
.feedback-container,
.market-analytics-container,
.profile-container,
.reports-container,
.setup-container,
.news-container,
.market-update-container,
.commission-process-container,
.crm-container {
	// General styles for form elements
	.form-control {
		border: none;
		font-size: 0.9rem;
		background: $halfBlue;
		color: $blue;
		padding: 0.6em 1em;
		height: auto;
		&:focus,
		&:active {
			box-shadow: none;
		}
		&.form-control-white {
			background: $white;
		}
		&.form-control-sm {
			height: calc(1.5em + 0.5rem + 2px);
			padding: 0.25rem 0.5rem;
			font-size: .875rem;
		}
	}

	.form-control-sm {
		height: calc(1.5em + 0.5rem + 2px);
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
	}

	.dx-dropdowneditor-input-wrapper {
		.dx-texteditor-input-container {
			border: none;
			background: $halfBlue;
			color: $blue;
			.dx-texteditor-input:not(.dx-colorbox-input) {
				font-size: 0.9rem;
				padding: 0.6em 1em;
				height: auto;
			}
		}
		.dx-texteditor-buttons-container {
			background-color: #d5dfec !important;
			color: #27569c !important;
		}
	}
	.form-element-container {
		padding-bottom: 1em;
		label {
			color: $black;
			font-weight: 500;
			font-size: 1em;
			margin: 0 0 0.5em 0;
			&.red {
				color: $red !important;
			}
			&.green {
				color: $green !important;
			}
			&.orange {
				color: $orange !important;
			}
		}
		label,
		.label-style {
			a {
				fa-icon {
					font-size: 0.85rem;
					vertical-align: middle;
					margin-right: 0rem;
				}
			}
		}
		.required::after {
			content: " *";
			color: $red;
		}
		.input-group {
			.input-group-append {
				.input-group-text {
					background: $gray;
					color: $blue;
					&.disabled {
						background: $lightGray;
						color: $outlineGray;
						&:hover {
							cursor: not-allowed;
						}
					}
					&.half-blue {
						background: $halfBlue;
					}
				}
			}
		}
		.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
			color: $white;
			background-color: $blue;
			border-color: $blue;
		}
		.unit-of-measure {
			position: absolute;
			top: 1.5rem;
			right: 2rem;
		}
		.form-symbol {
			top: 2.2rem;
			right: 1.5rem;
			position: absolute;
		}
		// Checkboxes and Radios
		.custom-control-input:checked ~ .custom-control-label::before {
			border-color: $pkBlue;
			background-color: $pkBlue;
		}

		.custom-control-label::before,
		.custom-control-label::after {
			top: 0;
		}
		// Custom Switches
		.custom-switch {
			label {
				&::before {
					top: 0.15em;
				}
				&::after {
					top: calc(0.15em + 2px);
				}
			}
			// Setup for a switch with "On" and "Off" labels
			.custom-switch-onOff {
				span::before {
					content: "Off";
				}
			}
			// Setup for a switch with "Active" and "Inactive" labels
			.custom-switch-active {
				span::before {
					content: "Inactive";
				}
			}
			// Makes the label light blue when switch is checked
			.custom-control-input:checked ~ .custom-switch-label {
				color: $lightBlue;
				font-weight: 600;
				&::before {
					border-color: $lightBlue;
					background-color: $lightBlue;
				}
				// Setup for a switch with "On" and "Off" labels
				&.custom-switch-onOff {
					span::before {
						content: "On";
					}
				}
				// Setup for a switch with "Active" and "Inactive" labels
				&.custom-switch-active {
					span::before {
						content: "Active";
					}
				}
			}
		}
		.supplier-note-title {
			color: $darkGray;
			font-size: 0.85em;
			font-weight: bold;
		}
		.supplier-note-body {
			font-size: 0.85em;
		}
		.special-instructions-form-text {
			color: $darkGray;
		}
		.compact-input {
			border: none;
			font-family: $mainFont;
			font-size: 0.85rem;
			color: $pkBlue;
			font-weight: 700;
			width: 100%;
			&::placeholder {
				color: $pkBlue;
				font-weight: 700;
			}
			&:focus-visible {
				outline: none;
				border-bottom: 1px solid $pkBlue;
				background-color: $halfBlue;
			}
		}
	}
	// Icon Radio Buttons
	.icon-radio-btn {
		input {
			position: absolute;
			left: 1.25rem;
			top: 0.75rem;
			opacity: 0;
			&:checked ~ label {
				font-weight: 700;
				color: $pkBlue;
				& img {
					opacity: 1;
				}
			}
		}
		label {
			transition: 0.25s ease-in-out;
			img {
				height: 2.5rem;
				width: auto;
				margin-right: 0.5rem;
				opacity: 0.25;
				transition: opacity 0.25s ease-in-out;
			}
			&:hover {
				cursor: pointer;
			}
		}
		&.disabled {
			input {
				&:checked ~ label {
					font-weight: 500;
					color: $darkGray;
					& img {
						opacity: 0.25;
						filter: grayscale(50%);
					}
				}
			}
			label {
				img {
					height: 2.5rem;
					width: auto;
					margin-right: 0.5rem;
					opacity: 0.25;
					filter: grayscale(50%);
				}
				&:hover {
					cursor: not-allowed;
				}
			}
		}
	}
	.ticket-comment-title {
		color: $darkGray;
		font-weight: bold;
	}
	.supplier-note-header {
		color: $darkGray;
		font-weight: bold;
	}
	.label-heading {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0;
		&.label-style-light {
			font-weight: 500;
			color: $darkGray;
		}
		&.label-style-bold {
			font-weight: 600;
			color: $black;
		}
		p {
			font-size: 0.75em;
			margin-bottom: 0.5rem;
			display: inline-block;
			&.count-validation {
				color: $green;
				font-weight: 600;
			}
		}
	}
	.label-style {
		font-size: 1em;
		margin: 0 0 0.5em 0;
		&.label-style-light {
			font-weight: 500;
			color: $darkGray;
		}
		&.label-style-bold {
			font-weight: 600;
			color: $black;
		}
		.label-link {
			font-weight: 600;
			&:link,
			&:visited,
			&:active {
				color: $pkBlue !important;
				text-decoration: none;
			}
			&:hover,
			&:active {
				color: $lightBlue !important;
				text-decoration: none;
			}
		}
		a {
			fa-icon {
				font-size: 0.85rem;
				vertical-align: middle;
				margin-right: 0rem;
			}
		}
	}

	.btn {
		text-transform: uppercase;
		font-size: 0.85em;
		font-weight: 700;
		border: none;
		line-height: 1.5 !important;
		padding: 0.75em 4em;
		&.btn-sm {
			padding: 0.65em 2em;
			font-size: 0.75rem;
		}
		&:disabled,
		&[disabled] {
			background-color: $lightGray !important;
			color: $darkGray !important;
			border: 1px solid $gray;
		}

		&.btn-primary,
		&.btn-default {
			background-color: $pkBlue !important;
			color: $white !important;
			&:hover {
				background-color: $blue !important;
			}
		}

		&.btn-secondary {
			background-color: $lightBlue !important;
			color: $white !important;
			&:hover {
				background-color: $blue !important;
			}
		}

		&.btn-light {
			border: 1px solid $pkBlue;
			color: $pkBlue;
			&:hover {
				background: $blue;
				border: 1px solid $blue;
				color: $white;
			}
			&:not(:disabled):not(.disabled):active,
			&:not(:disabled):not(.disabled).active,
			.show > .btn-light.dropdown-toggle {
				color: $white !important;
				background-color: $pkBlue;
				border-color: $pkBlue;
			}
		}

		&.btn-info {
			background-color: $halfBlue !important;
			border: 1px solid $halfBlue;
			color: $blue;
			&:hover {
				background: $blue !important;
				border: 1px solid $blue;
				color: $white !important;
			}
		}

		&.btn-smaller {
			font-size: 0.7rem;
			padding: 0.6em 2em;
		}

		&.btn-link {
			background: none;
			border: none;
			color: $lightBlue;
			&:hover {
				background: none;
				color: $pkBlue;
			}
		}

		&.btn-disabled,
		&[disabled] {
			background-color: $gray !important;
			color: $darkGray !important;
			&:hover {
				background-color: $gray !important;
				cursor: not-allowed;
			}
		}

		i,
		fa-icon {
			font-size: 1.1rem;
			vertical-align: middle;
			margin-right: 0rem;
		}

		&.btn-danger {
			color: $white;
		}

		label {
			color: inherit !important;
			font-weight: inherit !important;
			font-size: inherit !important;
			margin: inherit !important;
			&:hover {
				cursor: pointer;
			}
		}

		// Created to handle buttons with labels inside as text.
		&.btn-with-label {
			padding: 0 !important;
			label {
				color: inherit !important;
				font-weight: inherit !important;
				font-size: inherit !important;
				margin: inherit !important;
				padding: 0.65rem 0.75rem !important;
				&:not(.disabled):hover {
					cursor: pointer;
				}
				&.disabled:hover {
					cursor: not-allowed;
				}
			}
			input {
				display: none;
			}
		}
	}
	.input-group {
		.input-group-text {
			border: none !important;
		}

		.input-group-prepend,
		.input-group-append {
			.btn {
				padding: 0.375rem 0.75rem;
				i,
				fa-icon {
					color: $blue !important;
				}
			}
			.btn-default {
				background-color: $gray !important;
				color: $blue !important;
				&:not(.disabled):hover {
					background-color: $blue !important;
					color: $white !important;
					i,
					fa-icon {
						color: $white !important;
					}
				}
			}
			.btn-info {
				i,
				fa-icon {
					color: $blue !important;
				}
				&:hover {
					i,
					fa-icon {
						color: $white !important;
					}
				}
			}
		}
	}

	// "Shifted" Button for certain forms
	.shifted-button {
		position: relative;
		.btn {
			position: absolute;
			top: -1.15rem;
		}
	}

	// Styles for custom file input fields
	.custom-file {
		.custom-file-label {
			border: none;
			font-size: 0.9rem;
			background: $halfBlue;
			color: $blue;
			padding: 0.6em 1em;
			&:focus,
			&:active {
				box-shadow: none;
			}
			&::after {
				background-color: $gray !important;
				color: $blue !important;
				padding: 0.75em 0.75rem;
				text-transform: uppercase;
				font-size: 0.85em;
				font-weight: 700;
				border: none;
				line-height: 1.5 !important;
				height: auto;
			}
		}
		&.disabled {
			.custom-file-label {
				background: $lightGray;
				&:hover {
					cursor: not-allowed;
				}
				&::after {
					opacity: 0.65;
				}
			}
		}
	}

	.dx-button-content {
		i,
		fa-icon {
			background: $gray;
			color: $blue;
		}
	}

	.tooltip-icon {
		margin-left: 0.5rem;
		i,
		fa-icon {
			color: $lightBlue;
			font-size: 0.75rem;
			vertical-align: middle;
			margin-right: 0rem;
			&.text-danger {
				color: #dc3545 !important;
			}
		}
	}
	// Password Strength Test
	.password-test {
		ul {
			list-style-type: none;
			margin-top: 1rem;
			padding-left: 1rem;
			li {
				font-size: 0.75rem;
				margin-bottom: 0 !important;
				&.passed {
					color: $green;
				}
				&.failed {
					color: $red;
				}
			}
		}
	}

	.card {
		.footer {
			padding: 1.25rem;
		}
		.card-header {
			h2 {
				border-bottom: none;
			}
			.text-right {
				h2 {
					padding: 0.75em 4em 0 4em;
				}
			}
		}
		.card-body {
			.footer {
				padding: 0;
			}
		}
	}

	.delete {
		fa-stack {
			font-size: 1.5rem;
			height: auto;
			.circle-background {
				color: #dc3545;
			}
		}
	}

	// Div section for form elements
	.form-div {
		border-radius: 0.5rem;
		padding: 1.5rem;
		margin-bottom: 2rem;
		border: 1px solid $outlineGray;
		overflow-wrap: break-word;
		.form-divider {
			border: 1px solid $outlineGray;
		}
	}

	// Drag & Drop Upload Div
	#dropzone-external {
		width: 100%;
		height: auto;
		min-height: 215px;
		background-color: $offRowGray;
		border: 1px solid $iconLinkBlue;
		border-radius: 3px;
		padding: 10px;
		&:hover {
			cursor: pointer;
		}
		&.dropzone-active {
			border-style: solid;
		}
	}
	#dropzone-external > * {
		pointer-events: auto;
	}
	.widget-container > span {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 16px;
	}
	#dropzone-image {
		max-width: 100%;
		max-height: 100%;
	}
	#dropzone-text > p {
		font-weight: 600;
		color: $lightBlue;
		.download-icon {
			height: 3.5rem;
			width: auto;
		}
	}
	#upload-progress {
		display: flex;
		margin-top: 10px;
	}
	.flex-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	// Style for a Form Row Item Entry in Dashboard
	.form-row-item-entry {
		background-color: $lightGray;
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		margin-bottom: 1rem;
		.control-panel {
			padding: 1rem 1rem 0 1rem;
		}
		.control-panel-smaller {
			padding: 1rem 1rem 0 0.5rem;
		}
		.entry-form {
			background-color: $offWhite;
			border-radius: 0 0 0.5rem 0.5rem;
			padding-top: 1rem;
			min-height: 8rem;
		}
		&.selected {
			border: 1px solid $darkBlue;
			background-color: $gray;
		}
	}

	// Top Form Container
	.top-form {
		padding: 0 1rem;
		margin-bottom: 4rem;
		.form-container {
			border-top: 1px solid $pkBlue;
			border-left: 1px solid $outlineGray;
			border-right: 1px solid $outlineGray;
			border-bottom: 1px solid $outlineGray;
			border-radius: 0 0 0.5rem 0.5rem;
			.main-form-row {
				padding: 1.5rem 1rem 2.5rem;
				.reports-switch-label {
					line-height: 1.5rem;
					font-size: 0.85rem;
					font-weight: 500;
				}
				&.no-button {
					padding: 1.5rem 1rem 0.5rem;
				}
				// Makes the label light blue when switch is checked
				.custom-control-input:checked + .reports-switch-label {
					color: $lightBlue;
					font-weight: 600;
				}
			}
			.shifted-button {
				position: relative;
				.btn {
					position: absolute;
					top: -1.15rem;
				}
			}
			.shifted-button-div {
				position: relative;
				.shifted-div {
					position: absolute;
					top: -1.15rem;
				}
			}
		}
	}
}

// Form validation styles
.invalid {
	color: $red !important;
	.input-group,
	.form-control:not(.invalid-exclude),
	.pseudo-form-control,
	.dx-dropdowneditor-input-wrapper {
		border: 2px solid $red;
		color: $red;
		background-color: $pink;
		&:focus {
			box-shadow: none;
		}
	}
	.input-group {
		border-radius: 4px;
		.form-control {
			color: $red;
			background-color: $pink;
			border: none;
			&:focus {
				box-shadow: none;
			}
		}
		.input-group-append,
		.input-group-prepend {
			.input-group-text {
				color: $red;
				background-color: $pink !important;
				i,
				fa-icon {
					color: $red;
				}
			}
		}
	}
	label {
		color: $red !important;
	}
	.custom-control-label::before {
		border: 1px solid $red;
		background-color: $pink;
	}
	.custom-control-input:checked ~ .custom-control-label::before {
		border-color: $red;
		background-color: $red;
	}
}

// Validation messages
.invalid-feedback {
	font-weight: 600;
	display: block;
	color: $red;
}

// Helper Text
.helper-text {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: $darkGray;
	font-weight: 600;
}

// To override Bootstrap classes
.form-control {
	&:disabled,
	&:read-only:not(select) {
		background: $lightGray !important;
		cursor: not-allowed;
		& ~ label {
			color: $gray !important;
		}
	}
}

// Styles to fix the disabled state of checkboxes and radios
.custom-control-input:disabled:checked ~ .custom-control-label::before {
	opacity: 0.5;
}

.custom-control-input:disabled ~ .custom-control-label {
	color: $darkerOutlineGray !important;
	opacity: 1;
}

input[disabled][checked] {
	&.custom-control-input ~ .custom-control-label {
		color: $darkerOutlineGray !important;
		opacity: 1;
		&::before {
			background-color: $lightGray !important;
			border-color: $darkerOutlineGray !important;
		}
	}
}

input[type="radio"][disabled][checked] {
	&.custom-control-input ~ .custom-control-label {
		&::after {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a1a1a1'/%3e%3c/svg%3e") !important;
		}
	}
}

input[type="checkbox"][disabled][checked] {
	&.custom-control-input ~ .custom-control-label {
		&::after {
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23a1a1a1' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !important;
		}
	}
}

body > .dropdown,
body > .dropup {
	.dropdown-menu {
		font-size: 0.8rem;
		border: 1px solid $gray !important;
		border-radius: 0.5rem;
		-webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
		-moz-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
		box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
		.dropdown-item-override {
			padding: 0 !important;
		}
		.dropdown-item {
			padding: 0.25rem 1rem;
			&:link,
			&:visited,
			&:active {
				color: $darkGray !important;
				background-color: $white !important;
				&.semibold-link {
					font-weight: 600;
				}
				&.selected-item {
					font-weight: 600;
					color: $darkGray !important;
					background-color: $lightGray !important;
					.set-default-link {
						color: $lightBlue !important;
						margin-left: 0.5rem;
						float: right;
						position: relative;
						top: -1px;
					}
				}
			}
			&:hover,
			&:focus {
				background-color: $gray !important;
			}
			.energy-icon {
				height: 1.5rem;
				width: auto;
				margin: 0 0.5rem 0 0;
				vertical-align: bottom;
			}
		}
		&.scroll-dropdown {
			padding: 1rem;
			min-width: 18rem;
			.scrolling-area {
				max-height: 15rem;
				overflow-y: scroll;
			}
		}
		&.scroll-dropdown-with-items {
			padding: 0.5rem 1rem 0.5rem 0;
			.scrolling-area {
				max-height: 15rem;
				overflow-y: scroll;
				.container-column {
					padding-right: 0;
				}
			}
		}
		h3 {
			margin-bottom: 1rem;
		}
	}
}

.tooltip {
	.tooltip-inner {
		background-color: $tooltipBlue;
		color: $black;
	}
	&.show {
		opacity: 1;
	}
	&.bs-tooltip-right {
		& .arrow:before {
			border-right-color: $tooltipBlue !important;
		}
	}
	&.bs-tooltip-left {
		& .arrow:before {
			border-left-color: $tooltipBlue !important;
		}
	}
	&.bs-tooltip-bottom {
		& .arrow:before {
			border-bottom-color: $tooltipBlue !important;
		}
	}
	&.bs-tooltip-top {
		& .arrow:before {
			border-top-color: $tooltipBlue !important;
		}
	}
}

.row:not(.message-tabs-row) {
	.nav-tabs {
		li {
			a {
				height: 100%;
			}
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	.pk-modal,
	.instant-quote-container,
	.home-page-container,
	.contract-info-container,
	.supplier-container,
	.support-center-container,
	.feedback-container,
	.profile-container,
	.reports-container,
	.setup-container,
	.news-container,
	.market-update-container,
	.commission-process-container {
		// Style for a Form Row Item Entry in Dashboard
		.form-row-item-entry {
			.control-panel {
				padding: 1rem 1rem 0 1.75rem;
				&.full-width {
					padding: 1rem 1rem 0 1rem;
				}
			}
			.control-panel-smaller {
				padding: 1rem 1rem 0 0.75rem;
			}
			.entry-form {
				border-radius: 0 0.5rem 0.5rem 0;
				&.full-width {
					border-radius: 0 0 0.5rem 0.5rem;
				}
			}
		}
	}
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.pk-modal,
	.instant-quote-container,
	.home-page-container,
	.contract-info-container,
	.supplier-container,
	.support-center-container,
	.feedback-container,
	.profile-container,
	.reports-container,
	.setup-container,
	.news-container,
	.market-update-container,
	.commission-process-container {
		// Style for a Form Row Item Entry in Dashboard
		.form-row-item-entry {
			.control-panel {
				padding: 1rem 1rem 0 3rem;
				&.full-width {
					padding: 1rem 1rem 0 1rem;
				}
			}
			.control-panel-smaller {
				padding: 1rem 1rem 0 1.5rem;
			}
		}
	}
}

// 	Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1440px) {
	.pk-modal,
	.instant-quote-container,
	.home-page-container,
	.contract-info-container,
	.supplier-container,
	.support-center-container,
	.feedback-container,
	.profile-container,
	.reports-container,
	.setup-container,
	.news-container,
	.market-update-container,
	.commission-process-container {
		// Style for a Form Row Item Entry in Dashboard
		.form-row-item-entry {
			.control-panel {
				padding: 1rem 1rem 0 4rem;
				&.full-width {
					padding: 1rem 1rem 0 1rem;
				}
			}
			.control-panel-smaller {
				padding: 1rem 1rem 0 1rem;
			}
		}
	}
}

// 	Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {
	.pk-modal,
	.instant-quote-container,
	.home-page-container,
	.contract-info-container,
	.supplier-container,
	.support-center-container,
	.feedback-container,
	.profile-container,
	.reports-container,
	.setup-container,
	.news-container,
	.market-update-container,
	.commission-process-container {
		// Style for a Form Row Item Entry in Dashboard
		.form-row-item-entry {
			.control-panel {
				padding: 1rem 1rem 0 2rem;
				&.full-width {
					padding: 1rem 1rem 0 1rem;
				}
			}
			.control-panel-smaller {
				padding: 1rem 1rem 0 0.5rem;
			}
		}
	}
}

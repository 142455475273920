// 	Styles specific for the two CRM systems.
.crm-container-modal {
	.card-status {
		&.yellow-status {
			background-color: $yellow;
			color: $darkGray;
		}
		&.pkBlue-status {
			background-color: $pkBlue;
			color: $white;
		}
		&.orange-status {
			background-color: $orange;
			color: $white;
		}
		&.green-status {
			background-color: $green;
			color: $white;
		}
		&.ltBlue-status {
			background-color: $lightBlue;
			color: $white;
		}
		&.blue-status {
			background-color: $blue;
			color: $white;
		}
		&.gray-status {
			background-color: $gray;
			color: $darkGray;
		}
	}
}

.crm-container,
.dx-sortable-clone {
	.scrollable-list {
		height: 600px;
		width: 98%;
	}

	.sortable-cards {
		min-height: 580px;
		display: block;
	}

	.card {
		position: relative;
		background-color: white;
		box-sizing: border-box;
		width: 96%;
		padding: 0.65rem 1.25rem;
		margin: 0.65rem;
		cursor: pointer;
		div {
			overflow-wrap: anywhere;
		}
	}

	.card-priority {
		position: absolute;
		top: 0.65rem;
		bottom: 0.65rem;
		left: 0.4rem;
		width: 0.3rem;
		border-radius: 0.2rem;
		&.yellow-status {
			background-color: $yellow;
		}
		&.pkBlue-status {
			background-color: $pkBlue;
		}
		&.orange-status {
			background-color: $orange;
		}
		&.green-status {
			background-color: $green;
		}
		&.ltBlue-status {
			background-color: $lightBlue;
		}
		&.blue-status {
			background-color: $blue;
			color: $white;
		}
		&.gray-status {
			background-color: $gray;
			color: $darkGray;
		}
	}

	.card-subject {
		font-size: 0.85rem;
		font-weight: 600;
		max-width: 90%;
	}

	.card-state {
		font-size: 0.7rem;
		margin-bottom: 0.65rem;
	}

	.card-status {
		&.kanban-card-status {
			font-size: 0.7rem;
			padding: 0.35rem;
			margin-bottom: 1.25rem;
			font-weight: 600;
			border-radius: 0.2rem;
		}
		&.yellow-status {
			background-color: $yellow;
			color: $darkGray;
		}
		&.pkBlue-status {
			background-color: $pkBlue;
			color: $white;
		}
		&.orange-status {
			background-color: $orange;
			color: $white;
		}
		&.green-status {
			background-color: $green;
			color: $white;
		}
		&.ltBlue-status {
			background-color: $lightBlue;
			color: $white;
		}
		&.blue-status {
			background-color: $blue;
			color: $white;
		}
		&.gray-status {
			background-color: $gray;
			color: $darkGray;
		}
	}

	.card-lead-name {
		font-size: 0.7rem;
	}

	.card-phone {
		font-size: 0.7rem;
	}

	.card-email {
		font-size: 0.7rem;
		margin-bottom: 1.25rem;
	}

	.card-date {
		font-size: 0.7rem;
	}

	.card-assignee {
		font-size: 0.7rem;
	}
}

.crm-container {
	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 10rem 2rem 2rem 2rem;
	min-height: calc(100% - 61px);
	h1 {
		font-size: 1.5rem;
	}
	h2 {
		font-size: 1rem;
		color: $pkBlue;
	}
	h3 {
		font-size: 1rem;
	}
	h4 {
		color: $darkGray;
		font-size: 1rem;
	}

	.view-selector {
		.active {
			font-weight: 600;
			color: $pkBlue;
			padding: 0.25rem 0.35rem;
		}
		.left-link {
			border-right: 1px solid $pkBlue;
			padding: 0.25rem 0.35rem;
		}
		.right-link {
			border-left: 1px solid $pkBlue;
			padding: 0.25rem 0.35rem;
		}
		.link:not(:first-child) {
			border-left: 1px solid $pkBlue;
			padding: 0.25rem 0.35rem;
		}
	}

	// Kanban Styles
	#kanban {
		white-space: nowrap;
		font-family: $mainFont;
		font-weight: 500;
		// Making the columns stretch to one height.
		& > div.dx-scrollable-wrapper > div > div.dx-scrollable-content > div.dx-scrollview-content {
			display: flex;
			align-items: stretch;
		}
		.dx-theme-text-color {
			color: $darkGray !important;
		}
	}

	.broker-kanban,
	.customer-kanban {
		.list {
			width: 24.4%;
		}

		.list {
			border-radius: 0.4rem;
			margin: 0.3rem;
			background-color: $offRowGray;
			display: inline-block;
			vertical-align: top;
			white-space: normal;
		}

		.list-title {
			font-size: 1rem;
			padding: 0.8rem 0.6rem 0.6rem 0.8rem;
			margin-bottom: -0.6rem;
			font-weight: 600;
			cursor: pointer;
		}

		.reminder-icon {
			display: inline-block;
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			i,
			fa-icon {
				margin-right: 0;
			}
			&.active {
				a {
					color: $red;
				}
			}
			&.scheduled {
				a {
					color: $iconLinkBlue;
				}
			}
		}
	}

	// Grid Styles
	.broker-grid,
	.customer-grid {
		.reminder-icon {
			margin-left: 0.5rem;
			i,
			fa-icon {
				margin-right: 0;
			}
			&.active {
				color: $red;
			}
			&.scheduled {
				color: $iconLinkBlue;
			}
		}
	}
}

// Status Change Dropdown
// Consider making component.
.status-change {
	.dropdown-toggle {
		padding: 0.55rem 0.9rem 0.55rem 0.45rem !important;
		span {
			border-radius: 0.2rem;
			padding: 0.3rem 0.6rem 0.3rem 0.45rem;
			&.yellow-status {
				background-color: $yellow;
				color: $darkGray;
			}
			&.pkBlue-status {
				background-color: $pkBlue;
				color: $white;
			}
			&.orange-status {
				background-color: $orange;
				color: $white;
			}
			&.green-status {
				background-color: $green;
				color: $white;
			}
			&.ltBlue-status {
				background-color: $lightBlue;
				color: $white;
			}
			&.blue-status {
				background-color: $blue;
				color: $white;
			}
			&.gray-status {
				background-color: $gray;
				color: $darkGray;
			}
		}
	}
	.dropdown-menu {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		.dropdown-item {
			padding: 0.5rem 1rem;
			border-radius: 0.2rem;
			margin-bottom: 0.25rem;
			&:last-child {
				margin-bottom: 0;
			}
			&:link,
			&:visited,
			&:active {
				&.yellow-item {
					background-color: $yellow;
					color: $darkGray;
				}
				&.pkBlue-item {
					background-color: $pkBlue;
					color: $white;
				}
				&.orange-item {
					background-color: $orange;
					color: $white;
				}
				&.green-item {
					background-color: $green;
					color: $white;
				}
				&.ltBlue-item {
					background-color: $lightBlue;
					color: $white;
				}
				&.blue-item {
					background-color: $blue;
					color: $white;
				}
				&.gray-item {
					background-color: $gray;
					color: $darkGray;
				}
			}
			&:hover,
			&:focus {
				&.yellow-item,
				&.pkBlue-item,
				&.orange-item,
				&.green-item,
				&.ltBlue-item,
				&.blue-item,
				&.gray-item {
					background-color: $offWhite;
					color: $black;
				}
			}
		}
	}
}

// Stylings for CRM Modals
.lead-modal {
	padding: 1.5rem 0 !important;
	.lead-top-control {
		background-color: $lightGray;
		height: 3.3rem;
		padding: 1rem;
		margin-bottom: 1.75rem;
		&.empty-space {
			display: none;
		}
		.lead-top-control-btn {
			font-weight: 600;
			i,
			fa-icon {
				display: block;
				margin-right: 0;
				margin-bottom: 0.25rem;
			}
			span {
				display: block;
				font-size: 0.75rem;
			}
		}
	}
	.actionHistory {
		.collapse {
			display: block;
			height: 25rem !important;
			overflow: hidden;
			&.show {
				height: unset !important;
			}
		}
		.actionCollapseBtn {
			text-align: center;
			margin-bottom: 0;
			position: relative;
			z-index: 1;
			a {
				padding-top: 4rem;
				margin-top: -3rem;
				display: block;
				width: 100%;
				&.collapsed {
					background: linear-gradient(
						to bottom,
						rgba(255, 255, 255, 0) 0%,
						rgba(255, 255, 255, 1) 50%,
						rgba(255, 255, 255, 1) 100%
					);
					&:after {
						content: "Show all actions";
					}
				}
				&:not(.collapsed) {
					background: none;
					&:after {
						content: "Show less actions";
					}
				}
			}
		}
	}
	.lead-info {
		padding-bottom: 2rem;
		border-bottom: 1px solid $darkGray;
	}
}
// Setup for Email Campaign Modal
.drip-campaign {
	// Selection buttons in place of radio buttons.
	.type-select-card {
		label {
			display: block;
			border-radius: 0.5rem;
			padding: 1rem;
			margin: 0 auto !important;
			text-align: center;
			height: 13.5rem;
			width: 15rem;
			background-color: $offRowGray;
			border: 1px solid $darkGray;
			box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
			transition: all 0.5s ease-out;
			i,
			fa-icon {
				font-size: 4rem;
				margin-right: 0;
				display: block;
				color: $darkBlue;
				transition: color 0.5s ease-out;
			}
			i {
				margin-bottom: 1rem;
			}
			.card-title {
				color: $pkBlue;
				font-size: 1rem;
				font-weight: 600;
				margin-bottom: 0.5rem;
				display: block;
			}
			.card-copy {
				display: block;
				color: $darkGray;
				transition: color 0.5s ease-out;
			}
			&:hover {
				background-color: $offWhite;
				box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
				transition: all 0.5s ease-out;
				i,
				fa-icon {
					color: $pkBlue;
					transition: color 0.5s ease-out;
				}
				.card-copy {
					color: $black;
					transition: color 0.5s ease-out;
				}
			}
		}
		.select-card-radio {
			position: absolute;
			left: 0;
			z-index: -1;
			width: 1rem;
			height: 1.25rem;
			opacity: 0;
			&:checked ~ label {
				background-color: $white;
				border: 1px solid $pkBlue;
				box-shadow: none;
			}
		}
		&.invalid {
			label {
				background-color: $faintPink;
				border: 1px solid $red;
				i,
				fa-icon {
					color: $red;
				}
				.card-title {
					color: $red;
				}
				.card-copy {
					color: $red;
					font-weight: 600;
				}
				&:hover {
					i,
					fa-icon {
						color: $red;
					}
					.card-copy {
						color: $red;
						font-weight: 600;
					}
				}
			}
			.select-card-radio {
				&:checked ~ label {
					background-color: $white;
					border: 1px solid $pkBlue;
					box-shadow: none;
					i,
					fa-icon {
						color: $darkBlue;
					}
					.card-title {
						color: $pkBlue;
					}
					.card-copy {
						color: $darkGray;
						font-weight: 500;
					}
					&:hover {
						i,
						fa-icon {
							color: $pkBlue;
						}
						.card-copy {
							color: $black;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
	// Email Campaign Card
	.campaign-card {
		border-color: $darkGray;
		border-radius: 0.5rem;
		background-color: $offWhite;
		margin-bottom: 1rem;
		height: 100%;
		.card-body {
			padding: 0.75rem 0.75rem 0 0.75rem;
			.card-title {
				font-size: 1rem;
				color: $pkBlue;
				margin-bottom: 0;
			}
			.card-text {
				margin-bottom: 1rem;
				&.campaign-sub {
					font-size: 0.75rem;
					font-weight: 600;
					margin-bottom: 0.5rem;
				}
			}
		}
		.card-footer {
			background: none;
			border: none;
			font-size: 0.8rem;
			padding-left: 0.75rem;
			padding-right: 0.75rem;
			.card-link {
				i,
				fa-icon {
					margin-right: 0.2rem;
				}
				& + .card-link {
					margin-left: 0.5rem;
				}
			}
			.btn {
				font-size: 0.8em;
				line-height: 1 !important;
			}
		}
	}
	// Preview Image
	.email-preview {
		border: 1px solid $darkGray;
		margin-bottom: 1rem;
	}
	// Campaign Pagination
	.campaign-pagination {
		.caret-btn {
			i,
			fa-icon {
				font-size: 1.75rem;
				margin-right: 0;
			}
		}
		.num-btn {
			font-weight: 600;
			padding-right: 15px;
			padding-left: 15px;
			&.active {
				color: $pkBlue;
				font-weight: 700;
			}
		}
	}
	.position-change {
		position: unset;
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
	.lead-modal {
		.lead-top-control {
			.lead-top-control-btn {
				i,
				fa-icon {
					display: inline;
					margin-right: 0.5rem;
					margin-bottom: 0;
					font-size: 150% !important;
				}
				span {
					display: inline;
					font-size: 0.8rem;
				}
			}
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	// Stylings for CRM Modals
	.lead-modal {
		padding: 0 0 1.5rem 0 !important;
		.lead-top-control {
			&.empty-space {
				display: block;
			}
		}
		.take-action-div {
			padding: 0 1rem 0 1.5rem;
		}
		.lead-info {
			padding: 0 1.5rem 0 1rem;
			border-left: 1px solid $darkGray;
			border-bottom: none;
		}
	}

	.drip-campaign {
		.email-schedule-div {
			border-left: 1px solid $darkGray;
		}
	}
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.drip-campaign {
		.position-change {
			position: absolute;
		}
	}
}

// 	Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {
}

// 	Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {
}

// 	Configuration, fonts, colors, and mixins.

// Fonts
$mainFont: "Montserrat", sans-serif;
$roboto: "Roboto", sans-serif;
$helvetica: Helvetica, sans-serif;

// Colors
$white: #ffffff;
$halfWhite: rgba(255, 255, 255, 0.5);
$black: #000000;
$pkBlue: #1a2574;
$darkBlue: #214a86;
$lightBlue: #12a5ff;
$blue: #27569c;
$halfBlue: #e9eef5;
$lightGray: #ebebeb;
$gray: #d5dfec;
$darkGray: #707070;
$orange: #fc8f3b;
$blueGray: #6c91a7;
$placeholderGray: #6c757d;
$iconLinkBlue: #1e91d6;
$tooltipBlue: #cfe1fb;
$green: #009900;
$darkGreen: #43a047;
$lightGreen: #ddf5dd;
$yellow: #ffe600;
$red: #ff0000;
$pink: #f5dddd;
$offWhite: #fafafa;
$silver: #d4d2c9;
$diamond: #b9f2ff;
$offRowGray: #f5f5f5;
$outlineGray: #babec2;
$darkerOutlineGray: #a1a1a1;
$faintPink: #fff1f1;
$faintOrange: #fff2d9;
$faintGreen: #f1fff1;
$faintBlue: #f2f8ff;

// Animations
// Animation to fade items in
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Animation to fade items out
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

// Delayed bounce effect
@keyframes delayed-bounce {
	0% {
		transform: scale(1, 1.1) translateY(0);
	}
	66% {
		transform: scale(1, 1.1) translateY(0);
	}
	74% {
		transform: scale(1.1, 1) translateY(0);
	}
	83% {
		transform: scale(0.9, 1.2) translateY(-10px);
	}
	91% {
		transform: scale(1, 1.1) translateY(0);
	}
	100% {
		transform: scale(1, 1.1) translateY(0);
	}
}

// Pulse animation on FontAwesome Icons
@keyframes icon-pulse {
	0% {
		transform: scale(1);
	}
	90% {
		transform: scale(1);
	}
	95% {
		transform: scale(1.25);
	}
	100% {
		transform: scale(1);
	}
}

/* z-indexes with most significance */
$bulk-window-z: 10;
$home-page-nav-z: 100;
$home-page-nav-item-z: 100;
$modal-status-bar-z: 1050;
$navbar-z: 2000;
$header-z: 2000;
$header-bottom-row-z: 2000;
$feedback-float-z: 2049;
$dropdown-z: 2051;
$dx-overlay-wrapper-z: 2059;
$backdrop-modal-z: 2060;
$modal-z: 2061;
$tooltip-z: 2062;
$intro-js-z: 999999;
$dx-popup-wrapper-z: 9999999999999;

h1 {
	font-family: $mainFont;
	font-weight: 600;
	color: $pkBlue;
	font-size: 2rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

h2 {
	font-family: $mainFont;
	font-weight: 600;
	color: $black;
	font-size: 1.5rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

h3 {
	font-family: $mainFont;
	font-weight: 600;
	color: $pkBlue;
	font-size: 1.25rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

h4 {
	font-family: $mainFont;
	font-weight: 600;
	color: $black;
	font-size: 1rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

h5 {
	font-family: $mainFont;
	font-weight: 600;
	color: $darkGray;
	font-size: 0.85rem;
	&.add-underline {
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 3px;
	}
}

p {
	&.add-underline {
		border-bottom: 1px solid $darkGray;
		padding-bottom: 3px;
	}
	&.footnote {
		font-size: 0.75rem;
		line-height: 1.25rem !important;
	}
}

a {
	&:link,
	&:visited,
	&:active {
		color: $lightBlue;
		i,
		fa-icon {
			font-size: 125%;
			vertical-align: middle;
			margin-right: 0.5rem;
		}
	}
	&:hover,
	&:focus {
		color: $blue;
		text-decoration: none;
	}
	&.gray-link {
		color: $darkGray !important;
		&:link,
		&:visited,
		&:active {
			color: $darkGray !important;
		}
		&:hover,
		&:focus {
			color: $blue !important;
		}
	}
	&.red-link {
		&:link,
		&:visited,
		&:active {
			color: $red !important;
		}
		&:hover,
		&:focus {
			color: #990000 !important;
		}
	}
	&.orange-link {
		&:link,
		&:visited,
		&:active {
			color: $orange !important;
		}
		&:hover,
		&:focus {
			color: #c93a06 !important;
		}
	}
	&.green-link {
		&:link,
		&:visited,
		&:active {
			color: $green !important;
		}
		&:hover,
		&:focus {
			color: #006600 !important;
		}
	}
	&.light-blue-link {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue !important;
		}
		&:hover,
		&:focus {
			color: $darkBlue !important;
		}
	}
	&.dark-blue-link {
		&:link,
		&:visited,
		&:active {
			color: $pkBlue !important;
		}
		&:hover,
		&:active {
			color: $lightBlue !important;
		}
	}
	&.gray-icon {
		&:link,
		&:visited,
		&:active {
			i,
			fa-icon {
				color: $blueGray !important;
			}
		}
		&:hover,
		&:focus {
			i,
			fa-icon {
				color: $blue !important;
			}
		}
	}
	&.no-right-margin {
		i,
		fa-icon {
			margin-right: 0;
		}
	}
	&.blue-gray-icon {
		&:link,
		&:visited,
		&:active {
			i,
			fa-icon {
				color: $blueGray !important;
				font-size: 125%;
				vertical-align: middle;
				margin-right: 0.5rem;
			}
		}
		&:hover,
		&:focus {
			i,
			fa-icon {
				color: $blue !important;
			}
		}
	}
	&.no-right-margin {
		i,
		fa-icon {
			margin-right: 0;
		}
	}
	&.bigger-icon {
		i,
		fa-icon {
			font-size: 150% !important;
		}
	}
	&.disabled {
		color: $darkGray !important;
		&:link,
		&:visited,
		&:active {
			color: $darkGray !important;
		}
		&:hover,
		&:focus {
			color: $darkGray !important;
			cursor: not-allowed;
		}
	}
	// To reset the icon for special needs.
	&.reset-link-icon {
		i,
		fa-icon {
			font-size: unset;
			vertical-align: unset;
			margin-right: 0;
		}
	}
}

// Font Sizing
.font-size-075 {
	font-size: 0.75rem !important;
}
.font-size-085 {
	font-size: 0.85rem !important;
}
.font-size-095 {
	font-size: 0.95rem !important;
}
.font-size-1 {
	font-size: 1rem !important;
}
.font-size-125 {
	font-size: 1.25rem !important;
}
.font-size-15 {
	font-size: 1.5rem !important;
}
.font-size-175 {
	font-size: 1.75rem !important;
}
.font-size-2 {
	font-size: 2rem !important;
}

table {
	color: $darkGray;
	thead {
		th {
			border-bottom: 2px solid $outlineGray;
		}
	}
	tbody {
		tr {
			&.clickable-row {
				&:hover,
				&:focus {
					background-color: $halfBlue !important;
					color: $darkBlue !important;
					transition: 0.25s ease-in-out;
					cursor: pointer;
				}
			}
			&.clickable-row-cursor {
				&:hover,
				&:focus {
					cursor: pointer;
				}
			}
		}
	}
	th,
	td {
		border-top: 1px solid $outlineGray;
		&.no-top-border {
			border-top: unset;
		}
		&.centered-content {
			text-align: center;
			vertical-align: middle;
		}
		&.clickable-cell {
			&:hover,
			&:focus {
				background-color: $tooltipBlue !important;
				color: $blue !important;
				transition: 0.25s ease-in-out;
				cursor: pointer;
			}
		}
	}
}

// New style of tabs
.nav-tabs {
	justify-content: center;
	border-bottom: 2px solid $pkBlue;
	.nav-item {
		margin-bottom: 0;
		.nav-link {
			border: 0;
			font-weight: 600;
			&.active {
				color: $pkBlue;
				font-weight: 700;
				border-bottom: 2px solid $pkBlue;
			}
		}
	}
}
// For pages with an "Outer" tab and "Inner" tab.
//	Inner tab won't have border-bottom line
.nav-tabs-inner {
	&.nav-tabs-justify-content-start {
		.nav-tabs {
			justify-content: start !important;
		}
	}
	justify-content: center;
	.nav-item {
		margin-bottom: 0;
		.nav-link {
			border: 0;
			font-weight: 600;
			&.active {
				color: $pkBlue;
				font-weight: 700;
				border-bottom: 2px solid $pkBlue;
			}
		}
	}
}

hr.divider {
	border: 1px solid $pkBlue;
	&.gray {
		border-color: $darkGray;
	}
	&.outlineGray {
		border-color: $outlineGray;
	}
}

hr.divider1px {
	border-top: 1px solid $pkBlue;
	&.gray {
		border-color: $darkGray;
	}
	&.outlineGray {
		border-color: $outlineGray;
	}
}

.text-strong {
	font-weight: bold !important;
}

.medium-text {
	font-weight: 500;
}

.semibold-text {
	font-weight: 600;
}

strong,
.bold-text {
	font-weight: 700 !important;
}

.blue-text {
	color: $pkBlue !important;
}

.reg-blue-text {
	color: $blue;
}

.red-text {
	color: $red !important;
}

.orange-text {
	color: $orange !important;
}

.green-text {
	color: $green !important;
}

.white-text {
	color: $white !important;
}

.black-text {
	color: $black !important;
}

.gray-text {
	color: $darkGray !important;
}

.icon-blue-text {
	color: $iconLinkBlue !important;
}

.dark-blue-text {
	color: $darkBlue !important;
}

.blue-gray-text {
	color: $blueGray !important;
}

// Background Colors
.white-bg {
	background-color: $white !important;
}

.off-row-gray-bg {
	background-color: $offRowGray !important;
}

.half-blue-bg {
	background-color: $halfBlue !important;
}

.outline-gray-bg {
	background-color: $outlineGray !important;
}

.light-gray-bg {
	background-color: $lightGray !important;
}

.placeholder-gray-bg {
	background-color: $placeholderGray !important;
}

.light-green-bg {
	background-color: $lightGreen !important;
}

.faint-orange-bg {
	background-color: $faintOrange !important;
}

// Allows for elements to only hit a certain maximum width
// .width-limit will one day be retired and removed.
.maxwidth-800 {
	max-width: 800px;
}
.maxwidth-1080 {
	max-width: 1080px;
}
.maxwidth-1200 {
	max-width: 1200px;
}
.maxwidth-1440 {
	max-width: 1440px;
}

.w-auto {
	width: auto !important;
}

.w-10 {
	width: 10% !important;
}

.w-15 {
	width: 15% !important;
}

.w-33 {
	width: 33% !important;
}

.w-45 {
	width: 45% !important;
}

.w-75 {
	width: 75% !important;
}

// Needed to be able to apply this anywhere
.overflow-x-hidden {
	overflow-x: hidden;
}
// Making a rounded corner div
.rounded-corner-div {
	border: 1px solid $outlineGray;
	border-radius: 0.5rem;
	// For internal divs that might hug the corners.
	.top-rounded-corners {
		border-radius: 0.45rem 0.45rem 0 0;
	}
	.bottom-rounded-corners {
		border-radius: 0 0 0.45rem 0.45rem;
	}
}

// Big number and label combo used in the SaaS layouts
.big-number-label {
	font-size: 0.75rem;
	font-weight: 600;
	margin-bottom: 0;
}
.big-number {
	font-size: 1.75rem;
	color: $pkBlue;
	font-weight: 700;
	line-height: 1.75rem;
	sup {
		font-size: 1rem;
	}
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.width-limit {
		max-width: 1080px;
		margin-left: auto;
		margin-right: auto;
	}
}

// 	Styles specific for the Feedback Button and Modal

// Animation to move Feedback button
@keyframes move-button {
	0% {
		bottom: 1rem;
		right: 0.5rem;
	}
	16% {
		bottom: 0.5rem;
		right: 0;
	}
	33% {
		bottom: 0;
		right: -0.5rem;
	}
	49% {
		bottom: -0.5rem;
		right: -1rem;
	}
	66% {
		bottom: -1rem;
		right: -1.5rem;
	}
	82% {
		bottom: -1.5rem;
		right: -2rem;
	}
	100% {
		bottom: -2.5rem;
		right: -2.25rem;
	}
}

// Animation to fade items in
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Animation to fade items out
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

// Begin Feedback Button
.feedback-float {
	z-index: $feedback-float-z;
	&.full,
	&.minimized {
		position: fixed;
		.feedback-minimize {
			transition: all 0.1s ease-in-out;
			-webkit-transition: all 0.1s ease-in-out;
		}
		.feedback-button {
			margin-bottom: 0;
			text-align: center;
			a {
				transition: all 0.1s ease-in-out;
				-webkit-transition: all 0.1s ease-in-out;
				&:link,
				&:visited,
				&:active {
					background-color: rgba(255, 255, 255, 0.75);
					color: $lightBlue;
					text-decoration: none;
				}
				&:hover,
				&:focus {
					background-color: $lightBlue;
					color: $white;
					text-decoration: none;
				}
			}
		}
	}
	&.full {
		bottom: 1rem;
		right: 0.5rem;
		.feedback-minimize {
			display: block;
			margin-bottom: -0.75rem;
			text-align: right;
			color: $darkerOutlineGray;
			&:link,
			&:visited,
			&:active {
				color: $darkerOutlineGray;
			}
			&:hover,
			&:focus {
				color: $darkGray;
			}
		}
		.feedback-button {
			margin-bottom: 0;
			text-align: center;
			a {
				padding: 0.7rem 1rem;
				border-radius: 3rem;
				display: block;
				-webkit-box-shadow: 2px 2px 4px 0px rgba(50, 50, 50, 0.6);
				-moz-box-shadow: 2px 2px 4px 0px rgba(50, 50, 50, 0.6);
				box-shadow: 2px 2px 4px 0px rgba(50, 50, 50, 0.6);
				br {
					display: block;
				}
				.feedback-btn-text {
					font-weight: 600;
					font-size: 0.5rem;
					text-transform: uppercase;
					display: block;
				}
				i,
				fa-icon {
					font-size: 1.75rem;
					margin-bottom: 0.35rem;
				}
			}
		}
	}
	&.minimized {
		animation: move-button 0.15s 0.05s ease-out forwards;
		.feedback-minimize {
			display: none;
		}
		.feedback-button {
			a {
				padding: 0.7rem 3rem 2.8rem 1rem;
				border-radius: 3rem;
				display: block;
				-webkit-box-shadow: -2px -1px 4px 0px rgba(50, 50, 50, 0.6);
				-moz-box-shadow: -2px -1px 4px 0px rgba(50, 50, 50, 0.6);
				box-shadow: -2px -1px 4px 0px rgba(50, 50, 50, 0.6);
				.feedback-btn-text,
				br {
					display: none;
				}
				i,
				fa-icon {
					font-size: 1.5rem;
					margin-bottom: 0;
				}
			}
		}
	}
}
// End Feedback Button

// Begin Feedback Modal
.feedback-modal {
	background-color: rgba(26, 37, 116, 0.75);
	&.modal-dialog-centered-custom {
		min-height: 100% !important;
	}
	&.modal-dialog {
		width: 100%;
		max-width: 100%;
		margin: 0;
		.modal-content {
			border: none;
			border-radius: 0;
			.modal-header {
				width: 100%;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				border-bottom: none;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				.modal-title {
					margin-left: auto;
					color: $pkBlue;
					font-size: 1.25rem;
					font-weight: 500;
				}
				.close {
					color: $pkBlue;
					opacity: 1;
					&:link,
					&:visited,
					&:active {
						color: $pkBlue;
					}
					&:hover,
					&:focus {
						color: $lightBlue;
					}
				}
			}
			.modal-body {
				width: 100%;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				.container-fluid {
					padding: 0;
					.feedback-left {
						.feedback-question-01 {
							.form-check {
								padding: 0 0.5rem;
								.form-check-input {
									display: none;
								}
								.form-check-label {
									text-align: center;
									border-radius: 0.25rem;
									color: $pkBlue;
									background-color: $lightGray;
									width: 6.5rem;
									height: 6.5rem;
									font-size: 0.75rem;
									font-weight: 600;
									line-height: 1.15rem;
									padding-top: 1.25rem;
									transition: 0.2s ease-in-out;
									-webkit-transition: 0.2s ease-in-out;
									i,
									fa-icon {
										font-size: 1.75rem;
										margin-bottom: 0.25rem;
									}
									&:link,
									&:visited,
									&:active {
										color: $pkBlue;
										background-color: $lightGray;
									}
									&:hover,
									&:focus {
										color: $white;
										background-color: $pkBlue;
										cursor: pointer;
									}
								}
								input[type="radio"]:checked + label {
									color: $white;
									background-color: $pkBlue;
								}
							}
						}
					}
					.feedback-right {
						.form-control {
							background-color: $offRowGray;
							border: 2px solid $outlineGray;
							border-radius: 0.25rem;
							font-size: 0.85rem;
							font-weight: 500;
						}
						.form-check {
							padding: 0 0.5rem;
							.form-check-input {
								display: none;
							}
							.form-check-label {
								text-align: center;
								border-radius: 0.25rem;
								color: $pkBlue;
								background-color: $lightGray;
								width: 100%;
								font-size: 0.8rem;
								font-weight: 600;
								transition: 0.2s ease-in-out;
								-webkit-transition: 0.2s ease-in-out;
								padding: 0.5rem 0;
								&:link,
								&:visited,
								&:active {
									color: $pkBlue;
									background-color: $lightGray;
								}
								&:hover,
								&:focus {
									color: $white;
									background-color: $pkBlue;
									cursor: pointer;
								}
							}
							input[type="radio"]:checked + label {
								color: $white;
								background-color: $pkBlue;
							}
						}
						.btn-secondary {
							background: $lightBlue;
							border: 1px solid $lightBlue;
							color: $white;
							font-size: 0.85rem;
							font-weight: 600;
							&:hover {
								background: $blue;
							}
						}
					}
					.label-style,
					label {
						font-weight: 500;
						font-size: 0.8rem;
						margin-bottom: 0.5rem;
					}
					.btn-light {
						background: $white;
						border: 1px solid $pkBlue;
						color: $pkBlue;
						font-size: 0.85rem;
						font-weight: 600;
						&:hover {
							background: $pkBlue;
							color: $white;
						}
					}
				}
			}
		}
	}
}

// NPS & CSAT Modals
.nps-csat-modal {
	.modal-content {
		border: none;
		border-radius: 0.25rem;
		.modal-header {
			border-bottom: none;
			.close {
				color: $pkBlue;
				opacity: 1;
				&:link,
				&:visited,
				&:active {
					color: $pkBlue;
				}
				&:hover,
				&:focus {
					color: $lightBlue;
				}
			}
		}
		.modal-body {
			.container-fluid {
				padding: 0;
				.btn-group-toggle {
					.btn-light {
						padding: 0.375rem 0.25rem;
						&.active {
							background: $pkBlue;
							border: 1px solid $pkBlue;
							color: $white;
						}
						&:hover {
							background: $blue;
							border: 1px solid $blue;
							color: $white;
						}
						i,
						fa-icon {
							font-size: 2.5rem;
						}
						&.detractor {
							color: $red;
							border: 1px solid $white;
							&.active {
								background: $red;
								border: 1px solid $red;
								color: $white;
							}
							&:hover {
								color: $red;
								background: $pink;
								border: 1px solid $red;
							}
						}
						&.passive {
							color: $darkBlue;
							border: 1px solid $white;
							&.active {
								background: $darkBlue;
								border: 1px solid $darkBlue;
								color: $white;
							}
							&:hover {
								color: $darkBlue;
								background: $halfBlue;
								border: 1px solid $darkBlue;
							}
						}
						&.promoter {
							color: $green;
							border: 1px solid $white;
							&.active {
								background: $green;
								border: 1px solid $green;
								color: $white;
							}
							&:hover {
								color: $green;
								background: $faintGreen;
								border: 1px solid $green;
							}
						}
					}
				}
				.label-style,
				label {
					font-weight: 500;
					font-size: 0.8rem;
					margin-bottom: 0.5rem;
				}
				.form-control {
					background-color: $offRowGray;
					border: 2px solid $outlineGray;
					border-radius: 0.25rem;
					font-size: 0.85rem;
					font-weight: 500;
				}
				// Checkboxes and Radios
				.custom-control-input:checked ~ .custom-control-label::before {
					border-color: $pkBlue;
					background-color: $pkBlue;
				}
				.btn-primary {
					background: $pkBlue;
					border: 1px solid $pkBlue;
					color: $white;
					font-size: 0.85rem;
					font-weight: 600;
					&:hover {
						background: $blue;
					}
				}
				.btn-light {
					background: $white;
					border: 1px solid $pkBlue;
					color: $pkBlue;
					font-size: 0.85rem;
					font-weight: 600;
					&:hover {
						background: $pkBlue;
						color: $white;
					}
				}
			}
		}
	}
}

// Opening animation classes
@for $i from 1 through 5 {
	.openAnim#{$i} {
		opacity: 0;
		animation: fade-in 0.5s ease-out #{$i * 0.15}s forwards;
	}
}

// Fade Out Animation
.closeAnim {
	opacity: 1;
	animation: fade-out 0.5s ease-out 0.15s forwards;
}

// Feedback Display Page
.feedback-container {
	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem !important;
	min-height: calc(100% - 61px);
	padding-top: 12em !important;

	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}

	.big-number {
		font-weight: 700;
		//color: $pkBlue;
		font-size: 4rem;
		line-height: 4rem;
		&.promoter {
			color: $green;
		}
		&.passive {
			color: $darkBlue;
		}
		&.detractor {
			color: $red;
		}
	}

	// Report Form Container
	.reports-form {
		padding: 0 1rem;
		margin-bottom: 4rem;
		.form-container {
			border-top: 1px solid $pkBlue;
			border-left: 1px solid $outlineGray;
			border-right: 1px solid $outlineGray;
			border-bottom: 1px solid $outlineGray;
			border-radius: 0 0 0.5rem 0.5rem;
			.main-form-row {
				padding: 1.5rem 1rem 2.5rem;
				.reports-switch-label {
					line-height: 1.5rem;
					font-size: 0.85rem;
					font-weight: 500;
				}
				&.no-button {
					padding: 1.5rem 1rem 0.5rem;
				}
				// Makes the label light blue when switch is checked
				.custom-control-input:checked + .reports-switch-label {
					color: $lightBlue;
					font-weight: 600;
				}
			}
			.shifted-button {
				position: relative;
				.btn {
					position: absolute;
					top: -1.15rem;
				}
			}
			.shifted-button-div {
				position: relative;
				.shifted-div {
					position: absolute;
					top: -1.15rem;
				}
			}
			&.no-top-border {
				border-top: none;
			}
			h2 {
				font-size: 1rem;
				font-weight: 600;
				color: $pkBlue;
				border-bottom: 1px solid $pkBlue;
				padding-bottom: 0.25rem;
				&.no-underline {
					border-bottom: none;
				}
				&.gray {
					color: $darkGray;
				}
			}
		}
	}

	.feedback-entry-card {
		border: 1px solid $darkerOutlineGray;
		border-radius: 1rem;
		margin-bottom: 1rem;
		padding: 1.25rem 1.5rem 0.5rem;
		.top-row {
			border-bottom: 1px solid $darkerOutlineGray;
			h4 {
				i,
				fa-icon {
					font-size: 1.75rem;
					vertical-align: sub;
				}
				&.promoter {
					i,
					fa-icon {
						color: $green;
					}
				}
				&.passive {
					i,
					fa-icon {
						color: $darkBlue;
					}
				}
				&.detractor {
					i,
					fa-icon {
						color: $red;
					}
				}
			}
			.feedback-status {
				font-weight: 600;
				position: relative;
				top: -9px;
				:link,
				:visited,
				:active {
					.red {
						color: $red;
					}
					.dark-gray {
						color: $darkGray;
					}
				}
				:hover,
				:focus {
					.red,
					.dark-gray {
						color: $pkBlue;
					}
				}
				.feedback-status-text {
					display: none;
				}
				i,
				fa-icon {
					position: relative;
					top: 7px;
					font-size: 1.15rem;
					vertical-align: text-bottom;
				}
			}
			.feedback-notes {
				font-size: 0.75rem;
				&:hover,
				&:focus {
					color: $pkBlue !important;
				}
			}
		}
	}

	.chart-div {
		min-height: 25rem;
	}

	.gray-area {
		background-color: $offRowGray;
		border-radius: 0.5rem;
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
	// Begin Feedback Modal
	.feedback-modal {
		.modal-dialog {
			.modal-content {
				.modal-header {
					width: 536px;
					max-width: 536px;
					.modal-title {
						font-size: 1.5rem;
					}
				}
				.modal-body {
					width: 536px;
					max-width: 536px;
					.container-fluid {
						.feedback-left {
							.feedback-question-01 {
								.form-check {
									.form-check-label {
										width: 7rem;
										height: 7rem;
										font-size: 0.8rem;
										i,
										fa-icon {
											font-size: 1.9rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.feedback-container {
		.feedback-entry-card {
			.top-row {
				.feedback-status {
					.feedback-status-text {
						display: inline;
						font-weight: 600;
						font-size: 0.75rem;
					}
				}
			}
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	// Begin Feedback Modal
	.feedback-modal {
		.modal-dialog {
			.modal-content {
				min-height: 19.5rem;
				.modal-header {
					width: 728px;
					max-width: 728px;
				}
				.modal-body {
					width: 728px;
					max-width: 728px;
				}
			}
		}
	}

	.feedback-container {
		.reports-form {
			.form-container {
				h2 {
					font-size: 1.25rem;
				}
			}
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	// Begin Feedback Modal
	.feedback-modal {
		.modal-dialog {
			.modal-content {
				.modal-header {
					width: 780px;
					max-width: 780px;
				}
				.modal-body {
					width: 780px;
					max-width: 780px;
				}
			}
		}
	}
}

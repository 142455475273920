table {
	thead,
	tbody {
		th,
		td {
			&.min-width-15 {
				min-width: 15rem;
			}
			&.min-width-20 {
				min-width: 20rem;
			}
			&.min-width-25 {
				min-width: 25rem;
			}
		}
	}
	// Smaller Table
	&.table.table-sm {
		td,
		th {
			padding: 3px 7px;
		}
	}
	// Table with gray background
	&.table-gray {
		background-color: rgba(0, 0, 0, 0.05);
	}
	// Table with gray background
	&.table-white {
		background-color: #ffffff;
		tr,
		th,
		td {
			background-color: #ffffff;
		}
	}
	.cell-highlight {
		margin-left: -8px;
		margin-right: -8px;
		padding: 8px;
		background-color: #e6f4fb !important;
	}
	// Table with no outer border
	&.table-no-outer-border {
		thead {
			th {
				border-top: 0;
				text-align: center;
				border-left: 1px solid $darkerOutlineGray;
				border-right: 1px solid $darkerOutlineGray;
				border-bottom: 2px solid $darkerOutlineGray;
				&.no-btm-border {
					border-bottom: 0;
				}
				&:first-child {
					border-left: 0;
				}
				&:last-child {
					border-right: 0;
				}
			}
		}
		tbody {
			tr {
				td {
					text-align: center;
					border-left: 1px solid $darkerOutlineGray;
					border-right: 1px solid $darkerOutlineGray;
					border-top: 1px solid $darkerOutlineGray;
					border-bottom: 1px solid $darkerOutlineGray;
					&.no-btm-border {
						border-bottom: 0;
					}
					&.no-vert-borders {
						border-top: 0;
						border-bottom: 0;
					}
					&:first-child {
						border-left: 0;
					}
					&:last-child {
						border-right: 0;
					}
				}
				&:last-child {
					td {
						border-bottom: 0;
						padding-bottom: 1.5rem;
					}
				}
			}
		}
		&.table-sm {
			td,
			th {
				padding: 4px 8px;
			}
		}
	}
	// Table with only a top and bottom border
	&.table-top-bottom-border {
		border-bottom: 2px solid $darkerOutlineGray;
		thead {
			th {
				border-top: 0;
				text-align: center;
				border-left: 0;
				border-right: 0;
				border-bottom: 2px solid $darkerOutlineGray;
				&.no-btm-border {
					border-bottom: 0;
				}
			}
		}
		tbody {
			tr {
				td {
					border: 0;
				}
			}
		}
		&.table-sm {
			td,
			th {
				padding: 4px 8px;
			}
		}
	}
	&.table-nowrap {
		td,
		th {
			white-space: nowrap;
		}
	}
	&.datagrid-style {
		border: 1px solid $lightGray;
		thead {
			th,
			td {
				font-family: $roboto;
				font-size: 0.85rem;
				font-weight: 400;
				line-height: 1rem;
				padding: 0.6rem;
				vertical-align: middle;
				border-bottom-color: $lightGray;
			}
		}	
		tbody {
			th,
			td {
				font-family: $roboto;
				font-size: 0.85rem;
				font-weight: 400;
				line-height: 1rem;
				padding: 0.6rem;
				vertical-align: middle;
				border-top: none;
				border-bottom: none;
			}
		}	
	}
}

// Control Panel above table
.table-controls {
	background-color: $offRowGray;
	border-bottom: 1px solid $darkGray;
	.table-control-link {
		padding: 0;
		a {
			display: block;
			padding: 0.75rem 15px;
			font-size: 0.85rem;
			font-weight: 600;
			&:link,
			&:visited,
			&:active {
				i,
				fa-icon {
					color: $blueGray;
					font-size: 1rem;
					vertical-align: baseline;
				}
			}
			&:hover,
			&:focus {
				color: $white;
				background-color: $iconLinkBlue;
				i,
				fa-icon {
					color: $white;
				}
			}
			span {
				display: none;
			}
		}
	}
}

// Styles for the large breakpoint.
@media (min-width: 992px) {
	// Control Panel above table
	.table-controls {
		.table-control-link {
			a {
				span {
					display: inline;
				}
			}
		}
	}
}
